import React , {useEffect}from "react";
import axios from "axios";

function PlateNames({ value, name, label, onChange}) {    
    const {useState} = React;
const [allPlateNames, setallPlateNames] = useState([]);


const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};
  useEffect(() => {
    getPlateNames();
  }, [])

  const getPlateNames = async () =>{
    await axios.get(baseURL + "plates/" , config)
    .then(
        data => {
            const arr = data.data.message;
            setallPlateNames(arr);
        }
    ).catch( error => console.log(error))
  }

 
  return (
    <>
    <label htmlFor="input-field"> {label}</label>
    <select className="form-select" name={name} value={value} onChange={onChange}>
        <option value="0"> Please Select Plate </option>
        <option value="1">Thermal</option>
        <option value="2">CTPC</option>
{/* {allPlateNames.map( (x, i) =>
    <option key={x} value={x.id} >{x.plateName}</option>
)} */}
    </select>
    </>
  )
}

export default PlateNames
