import React , { useEffect}from "react";
import { Container, Row, Col } from "react-bootstrap";
import TopNavbar from '../navbar/navbar'; 
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from "axios";

function Customers() {
const{useState}=React;

const[inputs,setinputs]=useState({
  udfId : "",
  custName: "",
  custPhone: "",
  custEmail: "",
  custGstIN: "",
  fullAddress : "",
  custPincode : "",
  custBlStateCode : "27",
  custCity : "",
  custBlState : "",
  contactPerson : "",
  mobileNumber : "",
  allCustomerList : [],
  
Message : "",

});
// const navigate = useNavigate();

const[warncustCode,setwarncustCode]=useState(false);
const[warncustName,setwarncustName]=useState(false);
const[warncustPhone,setwarncustPhone]=useState(false);
const[warncustEmail,setwarncustEmail]=useState(false);
const[warncustGstIN,setwarncustGstIN]=useState(false);
const[warnfullAddress,setwarnfullAddress]=useState(false);
const[warncustPincode,setwarncustPincode]=useState(false);
const[warncustBlState,setwarncustBlState]=useState(false);
const[warncontactPerson,setwarncontactPerson]=useState(false);
const[warnmobileNumber,setwarnmobileNumber]=useState(false);
const[warncustBlStateCode,setwarncustBlStateCode]=useState(false);
const[warncustCity, setwarncustCity]=useState(false);
const [allCustomerList, setallCustomerList] = useState();
const[updateMethod, setupdateMethod]=useState(false);
const[submitButtonName, setsubmitButtonName]=useState("Create New Customer");
const[customerId, setcustomerId]=useState(0);
const [platesList, setPlatesList] = useState([]);
const [custPlates, setCustPlates] = useState([]);

const[danger,setdanger]=useState(true);
const[Message,setMessage]=useState(false);

const inputEvent= (event)=>{
const name=event.target.name;
const value=event.target.value;
if(name === "username"){
if(value.length>0){
setdanger(true);
}
}
setinputs((lastValue)=>{
return{
...lastValue,
[name]:value
}
});
};

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};


const custCode = inputs.custCode;
const custName = inputs.custName;
const custGstIN = inputs.custGstIN;     
const custEmail = inputs.custEmail;
const custPhone = inputs.custPhone;   
const fullAddress = inputs.fullAddress;
const custCity = inputs.custCity;
const custPincode = inputs.custPincode;
const custBlStateCode = inputs.custBlStateCode;        
const custBlState = inputs.custBlState;
const contactPerson = inputs.contactPerson;
const mobileNumber = inputs.mobileNumber;
const createdBy = localStorage.getItem('userId');

const bodyParameters = {
custCode : custCode,
custName:custName,
custGstIN:custGstIN,
custEmail:custEmail,
custPhone:custPhone,
fullAddress :fullAddress,
custCity :custCity,
custPincode:custPincode,
custBlStateCode : custBlStateCode,
custBlState : custBlState,
contactPerson :contactPerson,
mobileNumber : mobileNumber,
createdBy : createdBy,
activeStatus : "1",
platesList : custPlates

};


const submitForm = async (event) =>{
        console.log(localStorage.getItem('userId'));
    event.preventDefault();
setMessage("");
setwarncustCode(false);
setwarncustName(false);
setwarncustPhone(false);
setwarncustGstIN(false);
setwarncustEmail(false);
setwarncustPincode(false);
setwarncustBlStateCode(false);
setwarncustCity(false);
setwarnfullAddress(false);
setwarncustBlState(false);
setwarncontactPerson(false);
setwarnmobileNumber(false);
if(custCode === "" ){ setwarncustCode(true); } 
if(custName === "" ){ setwarncustName(true); } 
if(custEmail === "" ){ setwarncustEmail(true); } 
if(custPhone === "" ){ setwarncustPhone(true); } 
if(custGstIN === "" ){ setwarncustGstIN(true); } 
if( custCode !=="" && custName !== "" && custEmail !== ""){
  
  if (updateMethod) {
    axios
    .put(baseURL +"customers/"+ customerId,  
     bodyParameters, config)
    .then(data =>{
          console.log("data "+ data + " statuscode "+ data.data.statuscode);
          if(data.data.statuscode === "200"){     
          // window.location.href ='/customers';  
          clearAllInputs();
          getCustomers();
          setcustomerId("");
          setupdateMethod(false);
          setsubmitButtonName("Create New Customer");
        } 
      })
    .catch(error => console.log(error));
  } else {    
  axios
  .post(baseURL +"customers/register",  
   bodyParameters, config)
  .then(data =>{
        // console.log("data "+ data + " statuscode "+ data.data.statuscode);
        if(data.data.statuscode === "200"){     
        // window.location.href ='/customers';  
        clearAllInputs();
        getCustomers();

      } 
    })
  .catch(error => console.log(error));
  }  
}
}      

const getCustomers = async () =>{
  const res = await axios.get(baseURL + "customers/" , config)
  const array = res.data.message;
  array.map(function(e){
    e.clickEvent = () => testClickEvent( e.id);
});
setallCustomerList(array) ;
}
  
useEffect(() => {
  getCustomers();
  getPlates();
}, [])

const eventhandleChange = async (e) => {
        const index = custPlates.findIndex(object => object.plateSizeId === e.target.value);
    if (index === -1) {
        
        setCustPlates(custPlates => [...custPlates,
            {
                customerId: customerId,        
                plateSizeId :   e.target.value,
                plateSize :   e.target.options[e.target.selectedIndex].text,
                createdBy: localStorage.getItem('userId')
        
            }
        ] )
    }
      }
      
const getPlates = async () =>{
        const res = await axios.get(baseURL + "platesizes/" , config)
        setPlatesList(res.data.message) ;
      }
    

function clearData(){
  clearAllInputs();     
  setcustomerId("");
  setupdateMethod(false);
  setsubmitButtonName("Create New Customer");
}
function clearAllInputs(){
  setinputs(()=>{
    return{
    custPhone:"",
    custName : "",
    custEmail: "",      
    custCode: "",
    fullAddress : "",
    custBlState: "",      
    custBlStateCode: "",
    custCity : "",
    custPincode: "",      
    custGstIN: "",
    contactPerson: "",
    mobileNumber: ""
    }
    });
}


        async function testClickEvent(param) {
  clearAllInputs();
  axios.get(baseURL + "customers/" + param , config).then(async (data)=> {
        setinputs(()=>{
                return{
                custPhone:data.data.message.custPhone,
                custName : data.data.message.custName,
                custEmail: data.data.message.custEmail,      
                custCode: data.data.message.custCode,
                fullAddress : data.data.message.fullAddress,
                custBlState: data.data.message.custBlState,      
                custBlStateCode: data.data.message.custBlStateCode,
                custCity : data.data.message.custCity,
                custPincode: data.data.message.custPincode,      
                custGstIN: data.data.message.custGstIN,
                contactPerson: data.data.message.contactPerson,
                mobileNumber: data.data.message.mobileNumber
                }
                });
                const sendParam = {
                        customerId : data.data.message.id
                }
                axios.post(baseURL + "customers/platessizesbycustomer", sendParam, config).then(
                        async (respose) => {
                        if(respose.data.statuscode === "200"){
                               await setCustPlates(respose.data.message);
                        }
                        }     
                )
                await setcustomerId(data.data.message.id);
               await setupdateMethod(true);
               await setsubmitButtonName("Update Customer Data");
  })
  .catch();  

 
}

const data = () => {

        return {
columns: [
        {
          label: 'Sr. No.',
          field: 'id',
          width: 75,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Sr. No.',
          },
        },
        {
          label: 'Customer Name',
          field: 'custName',
          width: 270,
        },
        {
          label: 'Customer Phone',
          field: 'custPhone',
          width: 200,
        },{
          label: 'Customer Email',
          field: 'custEmail',
          width: 200,
        },        
      ],
      rows : allCustomerList,

    }
}
    return(
    <>
    <TopNavbar />
    <Container fluid>  
        <Row>
        <Col xs={12} md={6} className="rightBorder">
                <h4 className="text-center"> Customer List</h4>
        <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                className= "fontsize-14 minHeight"
                        striped
                        bordered
                        hover
                        scrollX
                        scrollY
                        minHeight="300px"
                        data={data()}
                        materialSearch
                        fullPagination
                        responsiveSm
                        entriesOptions={[10, 20, 25, 50, 100, 1000]}
                        entries={10}
                        pagesAmount={4}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
        </Col>
        <Col xs={12} md={6} >
        <h4 className="text-center"> {submitButtonName }</h4>
         <form autoComplete="off" onSubmit={submitForm}>
        <Row>
            <Col xs={4} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Customer Code </label>
                                <input className={` ${warncustCode ? "warning" : "form-control" }`} type="text" placeholder="Enter Customer Code " autoComplete="off" name="custCode" value={inputs.custCode ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Customer Code.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={8} md={8}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label > Customer Name </label>
                                <input className={` ${warncustName ? "warning" : "form-control" }`} type="text" placeholder="Enter Customer Name" autoComplete="off" name="custName" value={inputs.custName ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Customer Name.</p>
                            </div>                         
                    </div>
            </Col>
        </Row> 
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> GST Number</label>
                                <input className={` ${warncustGstIN ? "warning" : "form-control" }`} type="text" placeholder="12ABCD3456E1ZF" autoComplete="off" name="custGstIN" value={inputs.custGstIN ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Enter GST Number.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> Phone Number</label>
                                <input className={` ${warncustPhone ? "warning" : "form-control" }`} type="text" placeholder="+911234567890" autoComplete="off" name="custPhone" value={inputs.custPhone ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Phone Number.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> Email Address</label>
                                <input className={` ${warncustEmail ? "warning" : "form-control" }`} type="text" placeholder="dnyanaictp@gmail.com" autoComplete="off" name="custEmail" value={inputs.custEmail ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Email Address.</p>
                            </div>
                    </div>
            </Col> 
        </Row> 
        <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label> Customer Address</label>
                                <input className={` ${warnfullAddress ? "warning" : "form-control" }`} type="text" placeholder="No. 249, Aanand House, Shaniwar Peth, near Mutheshwar Chowk, Pune, Maharashtra 411030" autoComplete="off" name="fullAddress" value={inputs.fullAddress ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> City </label>
                                <input className={` ${warncustCity ? "warning" : "form-control" }`} type="text" placeholder="Pune" autoComplete="off" name="custCity" value={inputs.custCity ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter City.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={2}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Pincode</label>
                                <input className={` ${warncustPincode ? "warning" : "form-control" }`} type="text" placeholder="411001" autoComplete="off" name="custPincode" value={inputs.custPincode ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Pincode.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> State </label>
                                <input className={` ${warncustBlState ? "warning" : "form-control" }`} type="text" placeholder="Maharashtra" autoComplete="off" name="custBlState" value={inputs.custBlState ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter State</p>
                            </div>
                    </div>
            </Col>
            <Col xs={12} md={2}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> State Code </label>
                              <input className={` ${warncustBlStateCode ? "warning" : "form-control" }`} type="text" placeholder="27" autoComplete="off" name="custBlStateCode" value={inputs.custBlStateCode ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col>  
             
        </Row>
        <Row>
            <Col xs={12} md={8}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> Contact Person</label>
                                <input className={` ${warncontactPerson ? "warning" : "form-control" }`} type="text" placeholder="Enter Contact Person Name" autoComplete="off" name="contactPerson" value={inputs.contactPerson ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Contact Person Name.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> CP Mobile Number</label>
                                <input className={` ${warnmobileNumber ? "warning" : "form-control" }`} type="text" placeholder="Mobile Number" autoComplete="off" name="mobileNumber" value={inputs.mobileNumber ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Contact Person Mobile Number.</p>
                            </div>                         
                    </div>
            </Col>
                       
        </Row>
        <Row>
<Col xs={12} md={12}>
<div className="right-side">
                            <div className="input_text">
<select className='form-select' onChange={eventhandleChange} >

        <option> Please Size </option>
        {platesList.map((e, key) => {
                            return <option key={key} value={e.id} > {e.plateSizeName} </option>

                          })}
       </select>     
       </div>
       </div>  
</Col>

        </Row>
        <Row>
                <Col xs={12} md={4}>
                <div className="right-side">
                            <div className="input_text">
                <table className='table'>
                        <tr>
                        <td> Sr. No</td>
                        <td>Plate Sizes </td>
                        <td> Action </td>
                        </tr>
                {  custPlates.map((e, key) => {
                                        return(
                                        <tr>
                                                <td> {key}</td>
                                                <td>{e.plateSize}</td>
                                                <td></td>
                                        </tr>
                                        )
                        })
                }
                </table>
       </div>
       </div>
                </Col>
        </Row>
        <Row>
            <Col>
            <button type="button" className="btn btn-warning" onClick={clearData}> Clear </button>
            </Col>
            <Col>
            <button type="submit" className="btn btn-primary">{submitButtonName}</button>                
            </Col>
        </Row>
        {Message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {Message}
                        </div>
                        </div>
                    )}         
        </form>
        </Col>
        </Row>

         
        </Container>



    </>
    );
}
export default Customers;