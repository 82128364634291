import React , { useEffect}from "react";
import { Row, Col } from "react-bootstrap";
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from "axios";
// import PlatesAdd from "./platesadd";
import Sizes from "./sizes";

function Plates() {
const{useState}=React;

// const plateIdvalue='';
// const firstCallParent = "true";

const [allPlatesList, setallPlatesList] = useState();
const [plateId, setplateId] = useState();

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

  

const getPlates = async () =>{
  const res = await axios.get(baseURL + "plates/" , config)
  const array = res.data.message;
//   array.map(function(e){
//     e.clickEvent = () => onPlateClickEvent( e.id);
// });
setallPlatesList(array) ;
}
  
useEffect(() => {
    getPlates();
}, [])

// function onPlateClickEvent(param) {
//    setplateId(param);
//     // console.log("plateId "+ plateId);
// //   axios.get(baseURL + "plates/" + param , config)
//     //   setupdateMethod(true);
//     //   setsubmitButtonName("Update Customer Data");
// }

const data = () => {

        return {
columns: [
        {
          label: 'Plate Code',
          field: 'plateCode',
          width: 75,
        },
        {
          label: 'Plate Name',
          field: 'plateName',
          width: 200,
        },    
      ],
      rows : allPlatesList,

    }
}
    return(
    <>
   <Row>
    <Col className="rightBorder">
        <Row>
        <Col>
        <h4 className="text-center"> Plate Name List</h4>
        <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                className= "fontsize-14 minHeight"
                        striped
                        bordered
                        hover
                        scrollX
                        scrollY
                        maxHeight="200px"
                        data={data()}
                        materialSearch
                        fullPagination
                        responsiveSm
                        entriesOptions={[10, 20, 25, 50, 100, 1000]}
                        entries={10}
                        pagesAmount={4}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
        </Col>
        </Row>  
      </Col>
    
   </Row>



    </>
    );
}
export default Plates;