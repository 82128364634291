import React from "react";
import './signin.css';
import Laxmee from '../assets/images/dnyanai.PNG'

function Signup() {
const{useState}=React;

const[inputs,setinputs]=useState({
    firstName:"",
    lastName: "",
    phoneNumber :"",
email:"",
password:""
});

const[warnfirstName,setwarnfirstName]=useState(false);
const[warnlastName,setwarnlastName]=useState(false);
const[warnphoneNumber,setwarnphoneNumber]=useState(false);

const[warnemail,setwarnemail]=useState(false);
const[warnpass,setwarnpass]=useState(false);
const[danger,setdanger]=useState(true);

const[eye,seteye]=useState(true);
const[pass,setpass]=useState("password");


const inputEvent= (event)=>{
const name=event.target.name;
const value=event.target.value;
if(name === "email"){
if(value.length>0){
setdanger(true);
}
}
setinputs((lastValue)=>{
return{
...lastValue,
[name]:value
}
});
};

const submitForm=(e)=>{
e.preventDefault();
setwarnemail(false);
setwarnfirstName(false);
setwarnlastName(false);
setwarnphoneNumber(false);
setwarnpass(false);

if(inputs.firstName.length <1 ){ setdanger(false); }
if(inputs.firstName === "" ){ setwarnfirstName(true); }

if(inputs.lastName.length <1 ){ setdanger(false); }
if(inputs.lastName === "" ){ setwarnlastName(true); }

if(inputs.phoneNumber.length <1 ){ setdanger(false); }
if(inputs.phoneNumber === "" ){ setwarnphoneNumber(true); }

if(inputs.email.length <1 ){ setdanger(false); }
 if(inputs.email === "" ){ setwarnemail(true); }
  else if(inputs.password === "" ){ setwarnpass(true); } 
  else{ alert("Logged in Successfully"); } 
};



   const Eye=()=>{
    if(pass === "password"){
    setpass("text");
    seteye(false);
    }else{
    setpass("password");
    seteye(true);
    }
    }; 

    return(
    <>
        <div className="signincontainer" style={{'minHeight':'100vh',
    'display' : 'flex',
    'alignItems' : 'center',
    'justifyContent' : 'center'}}>
            <div className="card">
                <div className="form">
                    <div className="left-side">
                     <img  src={Laxmee} alt="Logo" width="80%"/>
                    </div>

                    <div className="right-side">
                        <div className="register">
                            <p> Alredy member? <a href="signin">Login here</a></p>
                        </div>

                        <div className="hello">
                            <h4>Welcome to Dnyanai CTP </h4>
                        </div>

                        <form onSubmit={submitForm}>
                            
                        <div className="input_text">
                                <input className={` ${warnfirstName ? "warning" : "" }`} type="text" placeholder="Enter Your First Name" autoComplete="off"  name="firstName" value={inputs.firstName} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Your Name.</p>
                            </div>
                            <div className="input_text">
                                <input className={` ${warnlastName ? "warning" : "" }`} type="text" placeholder="Enter Your Last Name" autoComplete="off"  name="lastName" value={inputs.lastName} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Last Name.</p>
                            </div>
                            <div className="input_text">
                                <input className={` ${warnphoneNumber ? "warning" : "" }`} type="text" placeholder="Enter Your Phone Number" autoComplete="off"  name="phoneNumber" value={inputs.phoneNumber} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a your phone Number.</p>
                            </div>

                            <div className="input_text">
                                <input className={` ${warnemail ? "warning" : "" }`} type="text" placeholder="Enter Your Name" autoComplete="off"  name="email" value={inputs.email} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a valid email address.</p>
                            </div>
                            <div className="input_text">
                                <input className={` ${warnpass ? "warning" : "" }`} type={pass} placeholder="Enter Password" autoComplete="off"  name="password" value={inputs.password} onChange={inputEvent} />
                                <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">Sign Up</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>



    </>
    );
}
export default Signup;