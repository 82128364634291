import React , { useEffect}from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from "axios";
import SizesAdd from "./sizeadd"; 

function Sizes() {
const{useState}=React;

const [allPlatesizeList, setallPlatesizeList] = useState();
const [plateSizeId, setplateSizeId] = useState();
const [plateSizeCode, setplateSizeCode] = useState();
const [plateSizeName, setplateSizeName] = useState();
const[updateMethod, setupdateMethod]=useState(false);
const[submitButtonName, setsubmitButtonName]=useState("Create New Plates");

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

  
  
useEffect(() => {
  getAllSizes(); 
},[])
function getAllSizes(){
  axios.get(baseURL + "platesizes/" , config)
  .then(data =>{
const array = data.data.message;
array.map(function(e){
  e.clickEvent = () => onPlateSizeClickEvent( e.id);
});
setallPlatesizeList(array) ;
}).catch();   
}
async function onPlateSizeClickEvent(param) {
  setplateSizeId(param);
  await axios.get(baseURL + "platesizes/" + param , config)
  .then(data =>{
    setplateSizeCode(data.data.message.plateSizeCode)
    setplateSizeName(data.data.message.plateSizeName)     
     setupdateMethod(true);
     setsubmitButtonName("Update Plate Size");   
 }).catch(); 

}
  const inputEvent= (event)=>{
    const name=event.target.name;
    const value=event.target.value;

    if(name==="plateSizeCode"){
      setplateSizeCode(value);
    }
    if(name==="plateSizeName"){
      setplateSizeName(value);
    }
    };
    function clearAllInputs(){
      // clearAllInputs();     
      setupdateMethod(false);
      setplateSizeCode("");
      setplateSizeName("");
      setsubmitButtonName("Create New Plates");
      getAllSizes();
    }
const data = () => {

        return {
columns: [
        {
          label: 'Plate Size Code',
          field: 'plateSizeCode',
          width: 125,
        },
        {
          label: 'Plate Size Name',
          field: 'plateSizeName',
          width: 200,
        },    
      ],
      rows : allPlatesizeList,

    }
}
    return(
    <>
   
    <Container fluid>
        <Row>
        <Col>
        <h4 className="text-center"> Plate Size List</h4>
        <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                className= "fontsize-14 minHeight"
                        striped
                        bordered
                        hover
                        scrollX
                        scrollY
                        maxHeight="200px"
                        data={data()}
                        materialSearch
                        fullPagination
                        responsiveSm
                        entriesOptions={[10, 20, 25, 50, 100, 1000]}
                        entries={10}
                        pagesAmount={4}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
        </Col>
        </Row>          
    <Row>
    <SizesAdd  plateSizeId ={plateSizeId} plateSizeCode={plateSizeCode}  plateSizeName={plateSizeName} updateMethod={updateMethod} submitButtonName={submitButtonName} getAllSizes={getAllSizes} inputEvent={inputEvent} clearAllInputs={clearAllInputs}  />
    </Row>
        </Container>
            </>
    );
}
export default Sizes;