import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL;
const config = {
  headers: {    
   'Access-Control-Allow-Origin' : '*',
   'Content-Type': 'application/json',
   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
   'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'
 }   
};
const login = async (username, password) => {
  await axios
    .post(API_URL + "users/authenticate/", {
      username,
      password,
    }, config)
    .then((response) => {
      if (response.data.message) {               
        localStorage.setItem("userDetails", JSON.stringify(response.data.message));  
        localStorage.setItem('token', JSON.stringify(response.data.message.token).slice(1, -1));
        localStorage.setItem('userId', JSON.stringify(response.data.message.id));
        localStorage.setItem('userRole', JSON.stringify(response.data.message.roles).slice(1, -1));
      }
      return response.data;
    });
}


const logout = () => {
    localStorage.clear();
}
const AuthService = {
    login,
    logout
  }
  
  export default AuthService;