import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import DnyanaiLogo from '../../assets/images/headerlogo.jpg';




function TopNavbar() {

  return (    
    <>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" >
      <Container>

        <Navbar.Brand ><Link to="/dashboard"><img src={DnyanaiLogo} alt="Logo"  width={'120px'} /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <NavDropdown title="Masters" id="collasible-nav-dropdown">
              <NavDropdown.Item as="li"><Link to="/customers">Customer</Link></NavDropdown.Item>
              <NavDropdown.Item as="li"> <Link to="/suppliers">Supplier</Link> </NavDropdown.Item>
              <NavDropdown.Item as="li"> <Link to="/items">Item/Plate</Link> </NavDropdown.Item>
            </NavDropdown>
            {/* <NavDropdown title="Purchase" id="collasible-nav-dropdown">
            <NavDropdown.Item as="li"><Link to="/purchaseinward"> Purchase /Inward Plate </Link></NavDropdown.Item>
            </NavDropdown> */}
            {/* <NavDropdown title="Sales" id="collasible-nav-dropdown">
            <NavDropdown.Item as="li"><Link to="/deliverychallans"> Delivery Challan </Link></NavDropdown.Item>
            <NavDropdown.Item as="li"><Link to="/deliverychallan"> Create Delivery Challan  </Link></NavDropdown.Item>
            <NavDropdown.Item as="li"><Link to="/salesinvoices"> Invoice List </Link></NavDropdown.Item>
            <NavDropdown.Item as="li"><Link to="/createsalesinvoice"> Create Invoice </Link></NavDropdown.Item>
            </NavDropdown> */}
            {/* <Nav.Link href="/sabhasadlist">Sabhsad</Nav.Link> */}
            <Nav.Link as={Link} to="/purchaseinward">Purchase</Nav.Link>
            <Nav.Link as={Link} to="/purchaseinwards">Purchase List</Nav.Link>
            
            <Nav.Link as={Link} to="/deliverychallans">DC List</Nav.Link>
            <Nav.Link as={Link} to="/deliverychallan">New DC</Nav.Link>

            <NavDropdown title="Sales" id="collasible-nav-dropdown">
              <NavDropdown.Item as="li"><Link to="/salesinvoicelist">Invoice List</Link></NavDropdown.Item>
              <NavDropdown.Item as="li"> <Link to="/createsalesinvoice">New Invoice</Link> </NavDropdown.Item>
              <NavDropdown.Item as="li"> <Link to="/cashinvoices">Cash Invoice List</Link> </NavDropdown.Item>
              <NavDropdown.Item as="li"> <Link to="/createcashinvoice">New Cash Invoice</Link> </NavDropdown.Item>
            </NavDropdown>




            {/* <Nav.Link as={Link} to="/salesinvoicelist">Invoice List</Nav.Link>
            <Nav.Link as={Link} to="/createsalesinvoice">New Invoice</Nav.Link>

            <Nav.Link as={Link} to="/cashinvoicelist">Cash Invoice List</Nav.Link>
            <Nav.Link as={Link} to="/createcashinvoice">New Cash Invoice</Nav.Link> */}

            <Nav.Link as={Link} to="/ratechart">Stock</Nav.Link>

            <Nav.Link as={Link} to="/partypayment">Payment </Nav.Link>
            <Nav.Link as={Link} to="/partyreceipt">Receipts</Nav.Link>
            {/* <NavDropdown title="Payments" id="collasible-nav-dropdown">
              <NavDropdown.Item as="li" > <Link to="/payments"> Payments </Link></NavDropdown.Item>
              <NavDropdown.Item as="li">
               <Link to="/generatepayment"> Customer Payment </Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="li"> <Link to="/deduction">Supplier Payment </Link></NavDropdown.Item>
              </NavDropdown> */}
              
          <NavDropdown title="Reports" id="collasible-nav-dropdown">
              <NavDropdown.Item as="li"><Link to="/taxreport">Tax/Cash Report</Link></NavDropdown.Item>
              <NavDropdown.Item as="li"> <Link to="/customerLedger">Ledger Report</Link> </NavDropdown.Item>
              </NavDropdown>
          </Nav>
          <Nav>            
          {/* <Nav.Link eventKey={2}>
             <Link to="/settings" >Settings </Link>
            </Nav.Link> */}
            <Nav.Link eventKey={2}>
            <Link to="/logout" >  Logout</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div className='mb-2'>

    </div>
    </>

  );
}

export default TopNavbar;