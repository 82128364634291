import React from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

function SizesAdd( props ) {  
const{useState}=React;
const[warnplateSizeCode,setwarnplateSizeCode]=useState(false);
const[warnplateSizeName,setwarnplateSizeName]=useState(false);

const[danger,setdanger]=useState(true);
const[Message,setMessage]=useState(false);


const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

const bodyParameters = {
plateId : '1' ,
plateSizeCode : props.plateSizeCode,
plateSizeName:props.plateSizeName,
createdBy :  localStorage.getItem('userId'),
updatedBy :  localStorage.getItem('userId'),
activeStatus : "1",

};


const submitForm = async (event) =>{        
    event.preventDefault();
setMessage("");
setwarnplateSizeCode(false);
setwarnplateSizeName(false);
if(props.plateSizeCode === "" ){ setwarnplateSizeCode(true); } 
if(props.plateSizeName === "" ){ setwarnplateSizeName(true); } 
if( props.plateSizeCode !=="" && props.plateSizeName !== ""){
  
  if (props.updateMethod) {
    axios
    .put(baseURL +"platesizes/"+ props.plateSizeId,  
     bodyParameters, config)
    .then(data =>{
          if(data.data.statuscode === "200"){  
          props.clearAllInputs();
        } 
      })
    .catch(error => console.log(error));
  } else {    
  axios
  .post(baseURL +"platesizes/register",  
   bodyParameters, config)
  .then(data =>{
        if(data.data.statuscode === "200"){     
        props.clearAllInputs();
      } 
    })
  .catch(error => console.log(error));
  }  
  // props.getAllSizes();
}
}      


    return(
    <>
        <Row>
        <Col>
        <h4 className="text-center"> {props.submitButtonName }</h4>
         <form autoComplete="off" onSubmit={submitForm}>
        <Row>
            <Col xs={4} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Plate Size Code </label>
                                <input className={` ${warnplateSizeCode ? "warning" : "form-control" }`} type="text" placeholder="Enter Plate Size Code " autoComplete="off" name="plateSizeCode" value={ props.plateSizeCode } onChange={props.inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Plate Size Code.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={8} md={8}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label > Plate Size Name </label>
                                <input className={` ${warnplateSizeName ? "warning" : "form-control" }`} type="text" placeholder="Enter Plate Size Name" autoComplete="off" name="plateSizeName" value={props.plateSizeName} onChange={props.inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Plates Size Name.</p>
                            </div>                         
                    </div>
            </Col>
        </Row> 
        <Row>
            <Col>
            <button type="button" className="btn btn-warning" onClick={props.clearData}> Clear </button>
            </Col>
            <Col>
            <button type="submit" className="btn btn-primary">{props.submitButtonName}</button>                
            </Col>
        </Row>
        {Message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {Message}
                        </div>
                        </div>
                    )}         
        </form> 
        </Col>
        </Row>
    </>
    );
}
export default SizesAdd;