import React , { useEffect}from "react";
import { Container, Row, Col } from "react-bootstrap";
import TopNavbar from '../navbar/navbar'; 
import axios from "axios";
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
// import { useEffect} from "react";
function  CustomerLedger() {
        
const{useState}=React;

const[fromdate,setfromdate]=useState((moment(new Date())).format('YYYY-MM-DD'));
const[todate,settodate]=useState((moment(new Date())).format('YYYY-MM-DD'));
const[customername,setcustomername]=useState();
const[customerid,setcustomerid]=useState();
const[selectOptions,setselectOptions]=useState([]);
const [CustomerList, setCustomerList] = useState([]);
const[ledgertype,setLedgertype]=useState(1);


const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 
                'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};



useEffect(() => {
        if(ledgertype == "1")
        {
                getCustomer();   
        }
        else if(ledgertype == "2")
        {
                getSupplier();  
        }
      }, [ledgertype])
    
      const getCustomer = async () => {
        const res = await axios.get(baseURL + "customers/", config)
        setCustomerList(res.data.message)
      }

      const getSupplier = async () => {
        const res = await axios.get(baseURL + "suppliers/", config)
        setCustomerList(res.data.message)
      }
  
      const eventhandleChange = async (e) => {
        setcustomername(e.target.options[e.target.selectedIndex].text);
        setcustomerid(e.target.value);
      }

      const eventhandleChangetype = async (e) => {
         setLedgertype(e.target.value);
       }
      


const getreport = async (event) =>{ 
        console.log(ledgertype);
if ((ledgertype !== null) && (customerid !== undefined))
        {
   const bodyParameters={
    fromDate:fromdate,
    toDate:todate,
    partyId:customerid
    }   
//event.preventDefault();
let callurl="";
if(ledgertype === 1)
{
        callurl="report/generatecustomerledgerreport/";
}
else if(ledgertype === 2)
{
        callurl="report/generatesupplierledgerreport/";      
}
console.log(callurl);
axios.post(baseURL+callurl,bodyParameters, config)
.then((data) => {
   let url
        if (data.data.statuscode === '200' && data.data.message) {
           url= data.data.message;
        }
        else
        {
          url= data.data.statuscode
        }
        const link = document.createElement('a');
        link.href = baseURL+"uploads/"+url; // Replace with the actual file path
        link.download = 'report.xlsx';
        // link.target = '_blank'; // Optional: Specify a different filename for the downloaded file
        link.click();
    })
.catch(error => console.log(error));
        }
        else
        {
             let errorMessage = 'Please Enter Ledger Type and Party Name';
             Swal.fire({
                position: 'center',
                icon: 'warning',
                title: errorMessage,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                    title: 'swal-fire-alert',
                },
            });
        }
}

        const containerStyle = {
          border: '2px solid #999999', // Specify border properties here
          padding: '20px', // You can also add other styles as needed
          // Add other styles as needed
        }


    return(
    <>
    <TopNavbar />
    <Container style={containerStyle}>  
        <Row>
       <Col xs={12} md={8} >
        <h4 className="text-center"></h4> 
        <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <h4 className="text-center">CREATE LEDGER</h4> 
                             </div>                         
                    </div>
            </Col>
            </Row>
        <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Ledger Type </label>
                                <select className="form-control" required="required" name="ledgertype" value={ledgertype} onChange={eventhandleChangetype}>
                                        <option value="1">Customer Ledger</option>
                                        <option value="2">Supplier Ledger</option>
                                </select>
                            </div>                         
                    </div>
            </Col>
            </Row>
            <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label > Party Name </label>
                            <select className="form-control" required="required" name="partyId" value={customerid} onChange={eventhandleChange}>
                        <option value=""> Select Party Name</option>
                        {
                          CustomerList.map((e, key) => {
                            return <option key={key} value={e.id} > {ledgertype == "1" ? e.custName : e.suppName}</option>
                                                       })
                        }

                      </select >
                            </div>                         
                    </div>
            </Col>
            </Row>
           <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > From Date </label>
                                <input className="form-control" type="date" name="fromdate" value={fromdate}  onChange={e => setfromdate(e.target.value)} />
                            </div>                         
                    </div>
            </Col>
            </Row>
            <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label > To Date </label>
                            <input className="form-control" type="date" name="todate" value={todate} onChange={e => settodate(e.target.value)} />
                            </div>                         
                    </div>
            </Col>
            </Row>
           
            <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                   
            <div className="right-side">
                            <div className="input_text">
                           <a className="btn2" type="button" onClick={getreport} 
                             target="_blank"  rel="noreferrer" >Genetare&nbsp;&nbsp;<i className="fa fa-download"></i></a>
                            </div>                         
                    </div>


                    {/* <a className="btn2" type="button" onClick={getreport} 
                    target="_blank"  rel="noreferrer" ><i className="fa fa-download"></i> Generate</a>                         */}
                   
            </Col>
            </Row>
        
        </Col>
        </Row>
        </Container></>
    );
}
export default CustomerLedger;