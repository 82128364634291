import React , {useState, useEffect}from "react";
import axios from "axios";

function CustomerSelect({ value, name, label, onChange}) {    
    const {useState} = React;
const [allCustomerList, setallCustomerList] = useState([]);


const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};
  useEffect(() => {
    getCustomers();
  }, [])

  const getCustomers = async () =>{
    await axios.get(baseURL + "customers/" , config)
    .then(
        data => {
            const arr = data.data.message;
            setallCustomerList(arr);
        }
    ).catch( error => console.log(error))
    // await setallCustomerList(res.data.message) ;
//   console.log(allCustomerList);
  }

 
  return (
    <>
    <label htmlFor="input-field"> {label}</label>
    <select className="form-select" name={name} value={value} onChange={onChange}>
        <option value=""> Please Select Customer</option>
{allCustomerList.map( (x, i) =>
    <option key={i} value={x.id} data-address={x.fullAddress} data-gstno={x.custGstIN} data-statecode={x.custBlStateCode} data-state={x.custBlState} data-custname={x.custName}> {x.custName} </option>
)}
    </select>
    </>
  )
}

export default CustomerSelect
