import React from 'react'
import { useState, useEffect } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import TopNavbar from '../navbar/navbar';
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from "axios";
import moment from 'moment';



const PartyReceipt = () => {


  const [] = useState({
    allReceiptslist: [],
    Message: "",
  });

  // State Defining useState

  const [paidAmount, setPaidAmount] = useState();
  const [narrations, setNarrtion] = useState();
  const [payId, setpayId] = useState();
  const [payDate, setPayDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [payMode, setPayMode] = useState();
  const [CustomerList, setCustomerList] = useState([]);
  const [partyId, setPartyId] = useState();
  const [partyName, setPartyName] = useState();
  const [instrumentNumber, setinstrumentNumber] = useState();
  const [referenceNumber, setreferenceNumber] = useState();
  const [warnpartyId, setwarnpartyId] = useState(false);
  const [warnpayDate, setwarnpayDate] = useState(false);
  const [warnPaidAmount, setwarnPaidAmount] = useState(false);
  const [warninstrumentNumber, setwarninstrumentNumber] = useState(false);
  const [allReceiptslist, setallReceiptslist] = useState();
  const [updateMethod, setupdateMethod] = useState(false);
  const [submitButtonName, setsubmitButtonName] = useState("Create New Receipt");
  const [danger, setdanger] = useState(true);
  const [Message, setMessage] = useState(false);
  const [accountNo, setaccountNo] = useState(false);
  const [accountnolist, setaccountnolist] = useState([]);


  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = `Bearer ` + localStorage.getItem('token');
  const config = {
    headers: {
      'Authorization': token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
  };


  const createdBy = localStorage.getItem('userId');

//BodyParameter

  const bodyParameters = {
    partyId: partyId,
    partyName: partyName,
    paidAmount: paidAmount,
    narrations: narrations,
    referenceNumber: referenceNumber,
    instrumentNumber: instrumentNumber,
    createdBy: createdBy,
    payMode: payMode,
    receiptDate: payDate,
    activeStatus: "1",
    accountNumber:accountNo
  };


// axios.get to get Customer

  const getCustomer = async () => {
    const res = await axios.get(baseURL + "customers/", config)
    setCustomerList(res.data.message)
  }

  const getaccountNo = async () => {
    const paran={
      status:"1"
    }
    const res = await axios.post(baseURL + "payments/getaccountdetails/", paran,config)
    setaccountnolist(res.data.message)
  }

// sync Function for testClickEvent

  async function testClickEvent(param) {

    await axios.get(baseURL + "receipts/" + param, config).then(
      data => {
        setsubmitButtonName("Update Receipt");
        setPaidAmount(data.data.message.paidAmount);
        setPartyId(data.data.message.partyId);
        setPartyName(data.data.message.partyName);
        setPayMode(data.data.message.payMode);
        setNarrtion(data.data.message.narrations)
        setreferenceNumber(data.data.message.referenceNumber)
        setPayDate(data.data.message.receiptDate)
        setinstrumentNumber(data.data.message.instrumentNumber)
        setpayId(data.data.message.id);
        setupdateMethod(true);
        setaccountNo(data.data.message.accountNumber);
        
      })
     
      .catch(error => console.log(error));
  }

// submit form function for submitting form details

const submitForm = async (event) => {
    event.preventDefault();
    setMessage("");
    setwarnpartyId(false);
    if (partyId === "") { setwarnpartyId(true); }
// axios to update using PUT method
    if (partyId !== "") {
      if (updateMethod) {
        axios.put(baseURL + "receipts/" + payId,
          bodyParameters, config)
          .then(data => {
           
            if (data.data.statuscode === "200") {
             getReceipts();
             alert("Receipt Updated Successfully !!!!");
             setPartyId("");
             setPartyName("Please select Supplier");
             setpayId("");
             setPayDate("");
             setPaidAmount("");
             setPayMode("");
             setinstrumentNumber("");
             setreferenceNumber("");
             setNarrtion("");
             setaccountNo("");
             setupdateMethod(false);
             setsubmitButtonName("Create New Receipt");
            }
          })
          .catch(error => console.log(error));
      } 
      else {
        axios.post(baseURL + "receipts/register",
            bodyParameters, config)
          .then(data => {
       
            if (data.data.statuscode === "200") {
                    getReceipts();
                    alert("Receipt Created Successfully !!!!");
                    setpayId("");
                    setPartyId("");
                    setPartyName("");
                    setPayDate("");
                    setPaidAmount("");
                    setPayMode("");
                    setinstrumentNumber("");
                    setreferenceNumber("");
                    setNarrtion("");
                    setaccountNo("");
                    setPartyId("");

            }
          })
          .catch(error => console.log(error));
      }
    }
  }

// axios to get data using getReceipt

  const getReceipts = async () => {
    const res = await axios.get(baseURL + "receipts/", config)
    const array = res.data.message;
    array.map(function (e) {
      e.clickEvent = () => testClickEvent(e.id);
    });
    setallReceiptslist(array);
  }

// use Effect to getReceipt and Customer

  useEffect(() => {
    getReceipts();
    getCustomer();
    getaccountNo();
  }, [])

//hanglechange for html element

  const eventhandleChange = async (e) => {
    setPartyName(e.target.options[e.target.selectedIndex].text);
    setPartyId(e.target.value);
  }

  const eventaccno = async (e) => {
    await setaccountNo(e.target.value);
  }

  const data = () => {

    return {
      columns: [
        // {
        //   label: 'Sr. No.',
        //   field: 'id',
        //   width: 75,
        //   attributes: {
        //     'aria-controls': 'DataTable',
        //     'aria-label': 'Sr. No.',
        //   },
        // },
        {
          label: 'Customer Name',
          field: 'partyName',
          width: 270,
        },
        {
          label: 'Amount',
          field: 'paidAmount',
          width: 200,
        }, {
          label: 'Date',
          field: 'receiptDate',
          width: 200,
        },
      ],
      rows: allReceiptslist,

    }
  }
  return (
    <>
      <TopNavbar />
      <Container fluid>
        <Row>
          <Col xs={12} md={8} className="rightBorder">
            <h4 className="text-center"> Receipt  List</h4>
            <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                    className="fontsize-14 minHeight"
                    striped
                    bordered
                    hover
                    scrollX
                    scrollY
                    minHeight="300px"
                    data={data()}
                    materialSearch
                    fullPagination
                    responsiveSm
                    entriesOptions={[10, 20, 25, 50, 100, 1000]}
                    entries={10}
                    pagesAmount={4}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
          </Col>
          <Col xs={12} md={4} >
            <h4 className="text-center"> {submitButtonName}</h4>
            <form autoComplete="off" onSubmit={submitForm}>

              <Row>

                <Col xs={12} md={8}>
                  <div className="right-side">
                    <div className="input_text">
                      <label > Customer Name </label>
                      <select className={` ${warnpartyId ? "warning" : "form-select"}`} name="partyId" value={partyId} onChange={eventhandleChange}>
                        <option value=""> Please Select Customer</option>
                        {
                          CustomerList.map((e, key) => {
                            return <option key={key} value={e.id} > {e.custName} </option>
                                                       })
                        }

                      </select >
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4}>

                  <div className="right-side">
                    <div className="input_text">
                      <label > Receipt Date </label>
                      <input className={` ${warnpayDate ? "warning" : "form-control"}`} type="date"
                        value={payDate} onChange={(e) => { setPayDate(e.target.value) }} />
                    </div>
                  </div>
                </Col>
                <Row>

                  <Col xs={12} md={6}>
                    <div className="right-side">
                      <div className="input_text">
                        <label>Received Amount </label>
                        <input type="number" className={` ${warnPaidAmount ? "warning" : "form-control"}`} placeholder="0" autoComplete="off" name="custGstIN" value={paidAmount} onChange={(e) => { setPaidAmount(e.target.value) }} />
                        <p className={` ${danger ? "danger" : ""}`}><i className="fa fa-warning"></i>Please Enter Paid Amount.</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className="right-side">
                      <div className="input_text">
                        <label htmlFor="paymode"> Paymode </label>
                        <select id="paymode" className="form-select" name="pay-mode" value={payMode} onChange={(e) => { setPayMode(e.target.value) }}>
                          <option value="">--Please choose an option--</option>
                          <option value="cash">Cash</option>
                          <option value="card">Card</option>
                          <option value="upi">UPI</option>
                          <option value="netbanking">Net Banking</option>
                        </select>
                      </div>
                    </div>
                  </Col>

                </Row>
              </Row>
              <Row>
              <Col xs={12} md={12}>
                  <div className="right-side">
                    <div className="input_text">
                      <label>Select Account Number </label>
                      <select className="form-select" name="accountNo" value={accountNo} onChange={eventaccno}>
                        <option value=""> Select account No</option>
                        {
                          accountnolist.map((e, key) => {
                            return <option key={key} value={e.accountnumber} >{e.accountnumber}</option>

                          })
                        }

                      </select >
                  </div>
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div className="right-side">
                    <div className="input_text">
                      <label> Instrument Number </label>
                      <input className={` ${warninstrumentNumber ? "warning" : "form-control"}`} type="text" placeholder="0" autoComplete="off" name="custGstIN" value={instrumentNumber} onChange={(e) => { setinstrumentNumber(e.target.value) }} />
                      <i className="fa fa-warning"></i>Please Enter  valide no
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={12}>
                  <div className="right-side">
                    <div className="input_text">
                      <label> Reference Number</label>
                      <input className="form-control" type='text' placeholder="Payment Referencec" autoComplete="off" name="custPhone" value={referenceNumber} onChange={(e) => { setreferenceNumber(e.target.value) }} />
                      <p className={` ${danger ? "danger" : ""}`}><i className="fa fa-warning"></i>Please enter a Payment Reference</p>
                    </div>
                  </div>
                </Col>

                <Col xs={12} md={12}>
                  <div className="right-side">
                    <div className="input_text">
                      <label> Narration</label>
                      <input type="text" placeholder="Payment Narration" autoComplete="off" name="custPhone" value={narrations} onChange={(e) => { setNarrtion(e.target.value) }} />
                      <p className={` ${danger ? "danger" : ""}`}><i className="fa fa-warning"></i>Please enter a Payment Narration</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button type="submit" className="btn btn-danger">Submit</button></Col>
              </Row>
              {Message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {Message}
                  </div>
                </div>
              )}
            </form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PartyReceipt;