import React, {useEffect,useState} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import CustomerSelect from '../components/CustomerSelect'
import InputField from '../components/InputField'
import Checkbox from '../components/checkbox';
import PlateNames from '../components/plateName'
import PlateSizes from '../components/plateSize'
import DcData from './dcdata'
import Invoiceprint from './DisplayInviocePrint'
import { Container, Row, Col } from 'react-bootstrap'
import TopNavbar from '../navbar/navbar';
import axios from 'axios';
import "./invoice.css";
import moment from 'moment';


function toJSONLocal(date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

const CreateInvoice = () => {
  const [srno, setSrno] =useState(1);
  const [inputValue, setInputValue] = useState({ invoiceDate: toJSONLocal((new Date((new Date().setDate(new Date().getDate()-1))))), invoiceNo: "",saleRate: "", quantity :"", rate : "", amount :"", narration :"" ,startDate :toJSONLocal(new Date()), endDate : toJSONLocal(new Date())});

  const { invoiceDate, invoiceNo, quantity, rate, saleRate, amount , startDate, endDate,narration,hsn} = inputValue;
  const [partyName, setpartyName] = useState();
  const [partyId, setpartyId] = useState();
  const [plateId, setplateId] = useState();
  const [partyCity, setPartyCity] = useState();
  const [partyState, setPartyState] = useState();
  const [partyStateCode, setPartyStateCode] = useState();
  
  const [plateName, setplateName] = useState();
  const [allPlateSizes, setallPlateSizes] = useState([]);
  const [plateSizeId, setplateSizeId] = useState();
  const [plateSize, setplateSize] = useState();
  const [partyAddress, setpartyAddress] = useState();
  const [partyGSTNo, setpartyGSTNo] = useState();
  const [rowsData, setRowsData] = useState([]);
  const [rowsDataaddon, setRowsDataaddon] = useState([]);
  const [deliveryQuantity, setdeliveryQuantity] = useState("0");
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [prevChallanData, setprevChallanData] = useState([]);
  const [prevplateRates, setprevplateRates] = useState([]);
  const [platequantity, setplatequantity] =useState();
  const [subtotal, setsubtotal] =useState(0);
  const [gstAmount, setgstamount] =useState(0);
  const [gstPercentage, setgstPercentage] = useState(18);
  const[grandTotal, setGrandTotal] = useState(0);
  const[alldata, setalldata] = useState(0);

  const [challanids, setchallanids] = useState([]);
  

  useEffect(()=>{
    getInvoiceNumber();
  }, []);



  
function handleShow(breakpoint) {
  setFullscreen(breakpoint);
  setShow(true);
}


const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy = localStorage.getItem('userId');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};



const getInvoiceNumber = () =>{ 

  var date = new Date();
  const currentMonth = ('0'+ (date.getMonth() +  1)).slice(-2);
  var formatedDate = `${date.getFullYear()}-${currentMonth}-${date.getDate()-1}`;

  //console.log("formatedDate : "+formatedDate);
  axios.post(baseURL + "invoices/getinvoicenno/", {currentMonth}, config)
  .then(
      data => {
         setInputValue({
          invoiceNo : data.data.message,
          invoiceDate :(moment(new Date())).subtract(1, 'days').format('YYYY-MM-DD')
         })
        //  inputValue.challanNo = data.data.message;
      }
  ).catch(error => console.log(error))
}


  const eventhandleChange = (e)=>{
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var address =  optionElement.getAttribute('data-address');
    var gstIn =  optionElement.getAttribute('data-gstno');
    var custName = optionElement.getAttribute('data-custname');
    var custCity = optionElement.getAttribute('data-city');
    var custState = optionElement.getAttribute('data-state');
    var custStateCode = optionElement.getAttribute('data-statecode');
    setpartyId(e.target.value);
    setpartyName(custName);
    setpartyAddress(address);
    setpartyGSTNo(gstIn);
    setPartyCity(custCity)
    setPartyState(custState)
    setPartyStateCode(custStateCode)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    // console.log(inputValue);
    // if((name === "endDate") || (name === "startDate")){
    //     getDeliveryChallanData();
    // }
    calculateall()
  };
  
  const eventhandleChangePlateName = (e) => {
    const {options, selectedIndex} = e.target;
    setplateId(e.target.value);
    setplateName(options[selectedIndex].innerHTML);
    axios.get(baseURL + "platesizes/all/" + e.target.value, config)
    .then(
        data => {
            const arr = data.data.message;
            console.log(arr)
            setallPlateSizes(arr);
        }
    ).catch(error => console.log(error))
  }

  const eventhandleChangePlateSize =(e) => {
    const {options, selectedIndex} = e.target;
    setplateSizeId(e.target.value);
    setplateSize(options[selectedIndex].innerHTML);

    axios.post(baseURL+'invoices/getPreviousRate', {
      partyId : partyId,
      plateId : plateId
    },config)
    .then((data) => {
      if(data.data.message !== null){
        setInputValue((prev) => ({
          ...prev,
          rate: data.data.message.saleRate,
        }))
      }
      
    } 
    )
    .catch(e => console.log(e))
  }

  
  const addRowintoTable = (e) => {
    const rowsInput={
      srno: srno,
      plateName : plateName,
      plateId : plateId,
      plateSizeName : plateSize,
      plateSizeId : plateSizeId,
      saleQuantity: quantity,
      narration: '', 
      hsn: hsn, 
      saleRate :rate,
      saleAmount : Number(quantity) * Number(rate),  
  } 
  setRowsData([...rowsData, rowsInput])    
  setSrno(srno + 1);
  var totdeliverquantity = parseFloat(deliveryQuantity) + parseFloat(quantity);
  setdeliveryQuantity(totdeliverquantity);
  calculateall()
  calculategstamoun()
  }

  const getDeliveryChallanData = () => {
    console.log(startDate, endDate, partyId)
    axios
    .post(baseURL+"invoices/getchallandatabypartyid/",  
     {startDate, endDate, partyId}, config)
     .then(data =>{ 
        if(data.data.statuscode === "200") {
          console.log(JSON.stringify(data))
          setprevChallanData(data.data.message);
            // setprevplateRates(data.data.rates);
        }
     }).catch(error => console.log(error))
  }

  const bodyParameters = {
    invoiceDate: invoiceDate,
    invoiceNo: invoiceNo,
    status: 1,       
    partyId: partyId,
    partyName: partyName,
    partyAddress: partyAddress,
    partyGSTNo: partyGSTNo,
    subTotal: subtotal,
    partyCity : "Pune",
    partyState : partyState ,
    partyStateCode : partyStateCode ,
    gstPercentage: gstPercentage,
    gstAmount:gstAmount,
    totalChallan: platequantity,
    grandTotal: grandTotal,
    invoicedata: rowsData,        
    createdBy : createdBy,
    challanids:challanids,
    challandates:startDate+" to "+endDate,
  }
  const submitForm = async (event) =>{
    event.preventDefault();
    axios
.post(baseURL+"invoices/createinvoice",
 bodyParameters, config)
 .then(data =>{ 
  if(data.data.statuscode === "200") {
      window.location.href ='/createsalesinvoice/'; 
}
 })
 .catch(error => console.log(error))
  }

const addtoList = async (prevChallanData,i)=> {
  // const arrc=[];
  // arrc.push(prevChallanData[i].id);

  //  setchallanids(arrc);
  //  console.log("dddd"+JSON.stringify(prevChallanData[i]));
  challanids.push({ 
    challanid : prevChallanData[i].id,
    invoiceNo : invoiceNo,
        })
  
  var rowsQuantity = 0;
  var saleRate =0;
  const found = rowsData.some(el => el.plateSizeId ===  prevChallanData[i].plateSizeId);
  if (!found) { 
    const  plateId = prevChallanData[i].plateSizeId;
   await axios.post(baseURL+ "invoices/getPreviousRate",  
 {partyId, plateId}, config)
.then(data =>{
  if(data.data.statuscode === '200'){
    if(data.data.rates !== undefined || data.data.rates > 0){
      saleRate = data.data.rates;
      
    }else{
      saleRate = 0
    }
  }
});
   rowsData.push({ 
    challanDate : prevChallanData[i].challanDate,
    plateName : prevChallanData[i].plateName,
    plateId : prevChallanData[i].plateId,
    plateSizeName : prevChallanData[i].plateSizeName,
    plateSizeId : prevChallanData[i].plateSizeId,
    saleQuantity: parseFloat(prevChallanData[i].quantity),
    narration: prevChallanData[i].narration,
    hsn: "",
    saleAmount: Number((parseFloat(saleRate) * parseFloat(prevChallanData[i].quantity)).toFixed(2)),
    saleRate : Number((parseFloat(saleRate).toFixed(2)))
     })
     setSrno(srno + 1); 
    }
     else {
      const rowupdatedData = rowsData.map(obj => {
        if (obj.plateSizeId === prevChallanData[i].plateSizeId) {
          rowsQuantity = prevChallanData[i].quantity + obj.saleQuantity;
          return {...obj, saleQuantity: rowsQuantity};           
        } 
        return obj;
      })
     await setRowsData(rowupdatedData);
    }
     
     console.log(rowsData);
     var totdeliverquantity = parseFloat(deliveryQuantity) + parseFloat(quantity);
     setdeliveryQuantity(totdeliverquantity);
     setprevChallanData(prevChallanData.filter((v, l) => l !== i));
     console.log(rowsData)
  // }
//   else
//   {
//     //console.log("dddd"+JSON.stringify(prevChallanData[i]));
// //   var rowsQuantity = 0;
// //   var saleRate =0;
// //   console.log("all : "+JSON.stringify(prevChallanData))
// //    const prevChallanDataa=prevChallanData.map((prevChallanDataa, index) => {
// //   console.log("Data checking : "+JSON.stringify(prevChallanDataa));
// //   const found = rowsData.some(el => el.plateSizeId ===  prevChallanDataa.plateSizeId);
// //   if (!found) { 
// //     const  plateSizeId = prevChallanDataa.plateSizeId;
// //     axios.post(baseURL+ "invoices/getPreviousRate",  
// //  {partyId, plateSizeId}, config)
// // .then(data =>{
// //   if(data.data.statuscode === '200'){
// //     saleRate = data.data.rates;
// //   }
// // });
// //    rowsData.push({ 
// //      srno:srno,
// //     challanDate : prevChallanDataa.challanDate,
// //     plateName : prevChallanDataa.plateName,
// //     plateId : prevChallanDataa.plateId,
// //     plateSizeName : prevChallanDataa.plateSizeName,
// //     plateSizeId : prevChallanDataa.plateSizeId,
// //     saleQuantity: prevChallanDataa.quantity,
// //     narration: prevChallanDataa.narration,
// //     saleAmount: (parseFloat(saleRate) * parseFloat(prevChallanDataa.quantity)).toFixed(2),
// //     saleRate :parseFloat(saleRate).toFixed(2)
// //      })
// //      setprevChallanData(prevChallanData.filter((v, l) => l !== index));
// //      setSrno(srno + 1); 
// //     }
// //      else {
// //       const rowupdatedData = rowsData.map(obj => {
// //         if (obj.plateSizeId === prevChallanDataa.plateSizeId) {
// //           rowsQuantity = prevChallanDataa.quantity + obj.saleQuantity;
// //           return {...obj, saleQuantity: rowsQuantity};           
// //         } 
// //         return obj;
// //       })
// //       setRowsData(rowupdatedData);
// //     }
     
// //      console.log("Rows data value : "+JSON.stringify(rowsData));
// //      var totdeliverquantity = parseFloat(deliveryQuantity) + parseFloat(quantity);
// //      setdeliveryQuantity(totdeliverquantity);
// //     setprevChallanData(prevChallanData.filter((v, l) => l !== index));

// //   })
//  // setprevChallanData(prevChallanData.filter((v, l) => l !== index));

// }
}

const   handleChangeTable = async (index, evnt)=>{
  const { name, value } = evnt.target;
  const rowsInput = [...rowsData];
  rowsInput[index][name] = value;
  
  rowsInput[index]['saleAmount'] = (rowsInput[index]['saleQuantity'] * rowsInput[index]['saleRate']);
  await setRowsData(rowsInput);
  await calculateall();
}

const calculateall = async ()=> {
  


  const subtot = rowsData.reduce((subtot, currentValue) => subtot + (currentValue.saleAmount), 0);
  const plateqty = rowsData.reduce((plateqty, currentValue1) => plateqty = Number(plateqty) + Number(currentValue1.saleQuantity), 0);
  

  await setsubtotal(subtot);
  
  await setplatequantity(plateqty);
  var gstcal = (subtot *  gstPercentage)/100;
  await  setgstamount(gstcal);    
  var grtot=gstcal + subtot;
  await setGrandTotal((grtot).toFixed(2));
}

const calculategstamoun = async(e)=>{
  var gstpercent = e.target.value;

   setgstPercentage(gstpercent);
   
   var gstamt=(subtotal *  gstpercent)/100;
   await  setgstamount(gstamt);
   var grtot=gstamt + subtotal;
   await setGrandTotal(Number((grtot).toFixed(2)));
}

useEffect(() => {
  calculateall()
  calculategstamoun()
},[rowsData])


    return (
   <>
   <TopNavbar />
      <Container>
        <Row>
       <Col xs={12} lg={8} sm={12}>
       <Row>
    <Col xs={12} lg={8} sm={8}>
       <CustomerSelect value={partyId} name="partyId" label="Customer Select" onChange={eventhandleChange} /></Col>
    <Col xs={12} lg={4} sm={4}> <InputField
         type="date"
         value={invoiceDate}
         placeholder="Invoice Date"
         label="Invoice Date"
         name="invoiceDate"
         onChange={handleChange}
       />
       </Col>

       </Row>
       <Row>
        <Col xs={12} lg={8} sm={8}>
      Address : {partyAddress}
<br/>
      GST Number : {partyGSTNo}
      </Col>
       <Col xs={12} lg={4} sm={4}>
       <InputField
         type="text"
         value={invoiceNo}
         placeholder="Invoice Number"
         label="Invoice Number"
         name="invoiceNo"
         onChange={handleChange}
       /></Col>
   </Row>
   <Row>
    <Col xs={4} sm={4} lg={4}>
    {values.map((v, idx) => (
        <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
          Get Delivery Challan
          {typeof v === 'string' && `below ${v.split('-')[0]}`}
        </Button>
      ))}
    </Col>
   </Row>
   <Row className='rowtoadd'>
   {/* <Col xs={6} lg={3} sm={2}><InputField
         type="text"
         value={narration}
         placeholder="Description"
         label="Description"
         name="narration"
         onChange={handleChange}
       /> </Col> */}
        <Col xs={12} lg={6} sm={6}>
       <Row>
          <Col xs={6} lg={4} sm={4} >
            <InputField
            type="number"
            value={hsn}
            placeholder="hsnno"
            label="HSN"
            name="hsnno"
            onChange={handleChange}
            style={{padding:'0'}}
          /> </Col>
        <Col xs={6} lg={4} sm={4}> <PlateNames value={plateId} name="plateId" label="Plate Name" onChange={eventhandleChangePlateName}/></Col>
        <Col xs={6} lg={4} sm={4}> <PlateSizes value={plateSizeId} name="plateSizeId" label="Plate Size" onChange={eventhandleChangePlateSize}  allPlateSizes={allPlateSizes}/></Col>
        </Row>
        </Col>

        <Col xs={12} lg={6} sm={6}>
       <Row>
          <Col xs={6} lg={3} sm={3}><InputField
              type="number"
              value={quantity}
              placeholder="Quantity"
              label="Quantity"
              name="quantity"
              onChange={handleChange}
            /> </Col>
            <Col xs={6} lg={3} sm={3}><InputField
              type="number"
              value={rate}
              placeholder="Rate"
              label="Rate"
              name="rate"
              onChange={handleChange}
            /> </Col>
            <Col xs={6} lg={3} sm={3}><InputField
              type="number"
              value={amount}
              placeholder="Amount"
              label="Amount"
              name="amount"
              onChange={handleChange}
            /> </Col>
          <Col xs={1} lg={3} sm={3}> <a className="btn btn-success margin-top-25" onClick={addRowintoTable}> + </a> </Col>
       </Row>
        </Col>
   </Row>  
   <Row>    
   <Col xs={12} lg={12} sm={12}> 
    <table className='borderTable'> 
            <thead>
                <tr>
                    <td>Sr. No</td>
                    <td>Date</td>
                    <td>HSN</td>
                    <td>Plate Size</td>
                    <td>Quantity</td>
                    <td>Rate</td>
                    <td>Amount</td>
                </tr>
            </thead>
            <tbody>
            {rowsData.map((x, i) =><tr><td className='width10'>{x.srno}</td><td className='width20'> {x.challanDate}</td><td className='width10'> <InputField
         type="text"
         value={x.hsn}
         placeholder="HSN"
         label=""
         name="hsn"
         onChange={(evnt)=>(handleChangeTable(i, evnt))} 
       />
       {/* <input type="text" value={x.saleRate} ></input> */}
       </td><td className='width30'>{x.plateSizeName}</td> <td className='width10'>{x.saleQuantity}</td>
             <td className='width10'> <InputField
         type="text"
         value={x.saleRate}
         placeholder="Sale Rate"
         label=""
         name="saleRate"
         onChange={(evnt)=>(handleChangeTable(i, evnt))} 
       />
       {/* <input type="text" value={x.saleRate} ></input> */}
       </td>
                    <td className='width20'> {x.saleAmount}</td> </tr> ) }
            </tbody>
            {/* <tfoot>
                <tr>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> Total </td>
                    <td> {deliveryQuantity}</td>
                </tr>
            </tfoot> */}
            <tfoot>
        <tr>
            <td className="text-end" colSpan={3}> </td>
            <td className="text-end" > Total Plates</td>
            <td className="text-end">{platequantity} </td>
            <td className="text-end"> Sub Total</td>
            <td className="text-end">{subtotal}</td>
            <td></td>
            
        </tr>
        <tr>
            <td className="text-end" colSpan={6}>GST @ 
            <select value={gstPercentage} onChange={(evnt)=>(calculategstamoun(evnt))}>
                <option value={0}> 0% </option>
                <option value={5}> 5% </option>
                <option value={12}> 12% </option>
                <option value={18}> 18% </option>
                <option value={28}> 28% </option>
            </select></td>
            <td className="text-end">{gstAmount}</td>
        </tr>
        <tr>
            <td className="text-end" colSpan={6}>CGST @ {gstPercentage/2} %</td>
            <td className="text-end">{gstAmount/2}</td>
        </tr>
        <tr>
            <td className="text-end" colSpan={6}>SGST @ {gstPercentage/2} %</td>
            <td className="text-end">{gstAmount/2}</td>
        </tr>
        <tr>
            <td colSpan={6} className="text-end"> Grand Total </td>
            <td className="text-end">{grandTotal}</td>
        </tr>
    </tfoot>
        </table>
    </Col>
    </Row> 
    <Row>
      <Col><a type="submit" className='btn btn-success' onClick={submitForm}> Submit </a></Col>
    </Row>
       </Col>   
       <Col xs={12} lg={4} sm={12}>
        <Invoiceprint partyName={partyName} gstNo={partyGSTNo} partyAddress={partyAddress} invoiceNo={invoiceNo} invoiceDate={invoiceDate} rowsData={rowsData} deliveryQuantity={deliveryQuantity} />
       </Col>
   </Row>

   <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Row> 
                <Col lg={2}>
          <Modal.Title> Delivery Challans</Modal.Title>
          </Col>
          <Col lg={4}>
          <InputField
         type="date"
         value={startDate}
         placeholder="Start Date"
         label="Start Date"
         name="startDate"
         onChange={handleChange}
       />
       </Col>
       <Col lg={4}>
       <InputField
         type="date"
         value={endDate}
         placeholder="End Date"
         label="End Date"
         name="endDate"
         onChange={handleChange}
       />
       </Col>
       <Col> <a className="btn btn-success margin-top-25" onClick={getDeliveryChallanData}> Get DC </a> </Col>
</Row>
        </Modal.Header>
        <Modal.Body>
        <Table striped bordered hover>
      <thead>
        <tr>
          {/* <th>Sr NO</th> */}
          <th>Date </th>
          {/* <th>HSN </th> */}
          {/* <th>Description</th> */}
          <th>Plate Name</th>
          <th>Plate Size </th>
          <th>Quantity </th>
          <th>
            
          {/* <button type="button" className='btn btn-success' onClick={() => addtoList(prevChallanData,alldata)}> Add All </button> */}
          
        Action 
             </th>
        </tr>
      </thead>
      <tbody>
        { prevChallanData.map((x, i) => 
          <tr>                
          {/* <td>{i + 1}</td> */}
          <td>{x.challanDate}</td>
          {/* <td></td> */}
          {/* <td>{x.narration}</td> */}
          <td>{x.plateName}</td>
          <td>{x.plateSizeName}</td>
          <td>{x.quantity}</td>
          <td>
         <button type="button" className='btn btn-success' onClick={() => addtoList(prevChallanData,i)}> Add </button>
          </td>
        </tr>
        ) }       
      </tbody>
    </Table>
        </Modal.Body>
      </Modal>


   </Container>
   </>
  )
}

export default CreateInvoice
