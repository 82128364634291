import React, { useEffect, useState } from 'react'
import TopNavbar from '../navbar/navbar'
import { Container, Row, Col } from 'react-bootstrap'
import DeliveryChallanPrint from './DeliveryChallanPrint'
import axios from 'axios'

const DcDownload = (params) => {

   

    const [dcNumber, setdcNumber] = useState(params.dcNumber);
    const [partyName, setpartyName] = useState();
    const [partyAddress, setpartyAddress] = useState();
    const [partyGSTNo, setpartyGSTNo] = useState();
    const [rowsData, setRowsData] = useState([]);
    const [challanDate, setchallanDate] = useState();
    const [challanNo, setchallanNo] = useState();
    const [deliveryQuantity, setdeliveryQuantity] = useState();

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = `Bearer ` + localStorage.getItem('token');
    const createdBy = localStorage.getItem('userId');
    const config = {
            headers: { 'Authorization' : token,      
                    'Access-Control-Allow-Origin' : '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
             }
    };
    useEffect(() => {
        axios.post(baseURL+"deliverchallans/getDcDetails",  
       { dcNumber }, config)
        .then(data =>{ 
            if(data.data.statuscode === "200"){
                setdcNumber(data.data.dcdetails.challanNo);
                setpartyName(data.data.dcdetails.partyName);
                setpartyAddress(data.data.dcdetails.partyAddress);
                setpartyGSTNo(data.data.dcdetails.partyGSTNo);
                setchallanDate(data.data.dcdetails.challanDate);
                setchallanNo(data.data.dcdetails.challanNo);
                setdeliveryQuantity(data.data.dcdetails.deliveryQuantity);
                setRowsData(data.data.dcDataDetails);
            }
console.log(data.data.dcDataDetails);
// console.log(data.data.dcDataDetails);
        })
    
    },[]);


  return (
    <> 
        <Container>
        <Row>
        <DeliveryChallanPrint  partyName={partyName} gstNo={partyGSTNo} partyAddress={partyAddress} challanNo={challanNo} challanDate={challanDate} rowsData={rowsData} deliveryQuantity={deliveryQuantity} />
        </Row>
        </Container>
    
    </>
  )
}

export default DcDownload