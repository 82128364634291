import React from 'react';
import { CDBDataTable } from 'cdbreact';

const SalesInvoices = () => {
  const handleClick = (id) => {
    // Do something with the ID of the clicked row
    console.log(`Clicked row with ID ${id}`);
  };

  const data = [
    { id: 1, name: 'John Doe', email: 'johndoe@example.com' },
    { id: 2, name: 'Jane Smith', email: 'janesmith@example.com' },
    { id: 3, name: 'Bob Johnson', email: 'bobjohnson@example.com' },
  ];

  const columns = [
    {
      field: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      field: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      field: 'button',
      label: '',
      render: (rowData) => (
        <button onClick={() => handleClick(rowData.id)}>Click me</button>
      ),
    },
  ];

  return (
    <CDBDataTable
      striped
      hover
      bordered
      data={data}
      columns={columns}
      sorter={true}
      responsive={true}
    />
  );
}

export default SalesInvoices;
