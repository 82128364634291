import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Dynanai from "../../assets/images/headerbar.jpg"
import "./invoice.css";

function Invoiceprint({ partyName, gstNo, partyAddress, invoiceNo, invoiceDate, rowsData, deliveryQuantity }) {
  return (
    <>
    <div className='fontSize9'>
    <Row> 
        <Col> <img src={Dynanai} width="100%" alt="Dnyanai Logo" /> </Col>
    </Row>
    <Row> 
        <Col className='text-center'>   Tax Invoice </Col>
    </Row>
    <Row >
        <Col xs={8} lg={8} sm={12}>To,<br/> {partyName} <br/> 
        {partyAddress} <br/>
        {gstNo}
        </Col>        
        <Col xs={4} lg={4} sm={12} > Bill No: {invoiceNo} <br/>
                                   Bill Date :  {invoiceDate} </Col>
    </Row>
    <Row>
        <Col>
        <table className='borderTable'> 
            <thead>
                <tr>
                    <td>Sr. No</td>
                    <td>Plate name</td>
                    <td>Plate Size</td>
                    <td>Quantity</td>
                    <td>Rate</td>
                    <td>Amount</td>
                    </tr>
            </thead>
            <tbody>
            {rowsData.map((x, i) =><tr><td className='width10'>{x.srno}</td><td className='width40'> {x.plateName}</td> <td className='width40'>{x.plateSizeName}</td> <td className='width10'>{x.quantity}</td><td>{x.saleRate}</td><td>{x.saleAmount}</td> </tr> ) }
            </tbody>
            <tfoot>
                <tr>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> Total </td>
                    <td> {deliveryQuantity}</td>
                </tr>
            </tfoot>
        </table>
        </Col>
    </Row>
    </div>
    </>
  )
}

export default Invoiceprint