import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, Image, StyleSheet} from '@react-pdf/renderer';
import { Row, Col } from 'react-bootstrap'
import Dynanai from "../../assets/images/headerbar.jpg"
import axios from 'axios';


var pathname = window.location.pathname.split('/');
var y=0;
function DcPrint() {

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    headerTitle: {
      fontWeight: 'bold',
    },
    textCenter: {
      textAlign: 'center',
      fontSize: 12
    },
    textRight: {
      textAlign: 'right',

    },
    textLeft: {
      textAlign: 'left'
    },
    textBold : {
      fontWeight : 'bold',
    },
    column1: {
      width: '96%',
      marginHorizontal :10,
      marginRight : 10,
      margin:10,
      flexDirection: 'row'
    },
    column2: {
      width: '50%',
    },
    column3: {
      width: '33%',
      flexDirection: 'row'
    },
    tableCol5: {
      width: '100%',
      flexDirection: 'row'
    },
    tableCol50: {
      width: '50%',
      flexDirection: 'row'
    },
    tableCol10 : {
      width:100
    },
    tableCol20 : {
      width:200
    },
    tableCol30 : {
      width:300
    },
    contentfontSize: {
      fontSize: 10
    },
    hrLIne : {
      borderBottom : 1 ,
    },
    hrLIneTop : {
      borderTop : 1 ,
    },
    borderAll : {
      border : 1,
      margin : 10
    },
    rightStart : {
      alignSelf : 'flex-end'
    }
  });
  //console.log(pathname[2]);
  const [dcNumber, setdcNumber] = useState(pathname[2]);
  const [partyName, setpartyName] = useState();
  const [partyAddress, setpartyAddress] = useState();
  const [partyGSTNo, setpartyGSTNo] = useState();
  const [rowsData, setRowsData] = useState([]);
  const [challanDate, setchallanDate] = useState();
  const [challanNo, setchallanNo] = useState();
  const [deliveryQuantity, setdeliveryQuantity] = useState();
  const [uniquesizes, setuniquesizes] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = `Bearer ` + localStorage.getItem('token');
  const config = {
    headers: {
      'Authorization': token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
  };
  useEffect( async () => {
    axios.post(baseURL + "deliverchallans/getDcDetails/",
      { dcNumber }, config)
      .then(async function (data) {
        if (data.data.statuscode === "200") {
          setRowsData(data.data.dcDataDetails);
          setdcNumber(data.data.dcdetails.challanNo);
          setpartyName(data.data.dcdetails.partyName);
          setpartyAddress(data.data.dcdetails.partyAddress);
          setpartyGSTNo(data.data.dcdetails.partyGSTNo);
          setchallanDate(data.data.dcdetails.challanDate);
          setchallanNo(data.data.dcdetails.challanNo);
          setdeliveryQuantity(data.data.dcdetails.deliveryQuantity);


           var uniquesizesd = Object.values(data.data.dcDataDetails.reduce((hash, item) => {
            if (!hash[item.plateSizeName]) {
                hash[item.plateSizeName] = { plateSizeName: item.plateSizeName, quantity: 0 };
            }
            hash[item.plateSizeName].quantity += item.quantity;
            return hash;
        }, {}));

        setuniquesizes(uniquesizesd);
        
        }
       })

  }, []);

  return (

    <Document>
      <Page size="A4">
        <View>
          <View>
            <Image src={Dynanai} width="100%" alt="Dnyanai Logo" >  </Image>
          </View>
          <View>
            <Text style={[styles.textCenter, styles.headerTitle]}>   Delivery Challan </Text>
          </View>
          <View style={styles.borderAll}>
          <View style={styles.column1}>
          <View  style={[styles.column2, styles.textLeft, styles.contentfontSize]} > 
            <Text>To : </Text>
            <Text> {partyName}</Text>
            <Text> {partyAddress}</Text>
            <Text> GST No : {partyGSTNo}</Text>
            </View>
            <View  style={[styles.column2, styles.textRight, styles.contentfontSize]} >
            <Text > DC No: {challanNo} </Text>
            <Text > DC Date :  {challanDate} </Text>
            </View>
            </View>
         

          <View style={[styles.tableCol5, styles.hrLIne, styles.hrLIneTop]}>
            <Text style={[styles.headerTitle, styles.tableCol10, styles.textCenter]}> Sr. No. </Text>
            {/* <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Date </Text> */}
            <Text style={[styles.headerTitle, styles.tableCol30, styles.textCenter]}> Job Name </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Plate Size </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Quantity </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Job Card No. </Text>
          </View>

          {
           
          rowsData.map((x, i) =>
            
           <View style={[styles.tableCol5, styles.hrLIne, styles.contentfontSize]}>
            <Text style={[styles.contentfontSize, styles.tableCol10, styles.textCenter, styles.contentfontSize]}> {i+1} </Text>
            {/* <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> {x.challanDate} </Text> */}
            <Text style={[styles.tableCol30, styles.textCenter, styles.contentfontSize]}> {x.narration} </Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.plateSizeName}</Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.quantity} </Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.jobCardno} </Text>
          </View> )}
          <View style={[styles.tableCol5, styles.hrLIne]}>
            <Text style={[styles.headerTitle, styles.tableCol10, styles.textCenter]}>  </Text>
            {/* <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> {x.challanDate} </Text> */}
            <Text style={[styles.headerTitle, styles.tableCol30, styles.textCenter]}>  </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Total Quantity </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> {deliveryQuantity} </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}>   </Text>
          </View>
      <View style={[styles.rightStart]}>
          <View style={[styles.hrLIne, styles.column3]}>
              <Text style={[styles.textCenter, styles.contentfontSize]}> Plate Size Details </Text>            
            </View> 

          {
          uniquesizes.map((x, i) => 
          // <Text style={{fontsize:12}}>{x.plateSizeName} - {x.quantity}</Text>
              <View style={[styles.hrLIne, styles.column3]}>
              <Text style={[styles.textLeft, styles.contentfontSize]}> {x.plateSizeName} - </Text>
              <Text style={[styles.textRight, styles.contentfontSize]}> {x.quantity} </Text>
            </View> 
          )}
          </View>

</View>
          
         

          <Text>
            <table className='borderTable'>
              <thead>
                <tr>
                  <td>Sr. No</td>
                  <td>Description</td>
                  <td>Plate name</td>
                  <td>Plate Size</td>
                  <td>Quantityg</td>
                </tr>
              </thead>
            
              <tbody>
                <tr>
                <td>Sr. No</td>
                  <td>Description</td>
                  <td>Plate name</td>
                  <td>Plate Size</td>
                  <td>Quantity888</td>
                </tr>
                {/* {//console.log(rowsData)} */}
                {rowsData.map((x, i) => <tr><td className='width10'>{x.id}</td><td className='width40'> {x.narration}</td><td className='width20'> {x.plateName}</td> <td className='width20'>{x.plateSizeName}</td> <td className='width10'>{x.quantity}</td> </tr>)}
              </tbody>
              <tfoot>
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> Total </td>
                  <td> {deliveryQuantity}</td>
                </tr>
              </tfoot>
            </table>
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export default DcPrint