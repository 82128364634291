import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, Image, StyleSheet} from '@react-pdf/renderer';
import { Row, Col } from 'react-bootstrap'
import Dynanai from "../../assets/images/headerbar.jpg"
import axios from 'axios';
import moment from 'moment';


var pathname = window.location.pathname.split('/');
var y=0;

function PurchaseInwardPrint() {

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    headerTitle: {
        fontWeight: 'bold',
        fontSize: 22,
    },
    textCenter: {
      textAlign: 'center',
      fontSize: 12
    },
    textRight: {
      textAlign: 'right',

    },
    textLeft: {
      textAlign: 'left'
    },
    textBold : {
      fontWeight : 'bold',
    },
    column1: {
      width: '96%',
      marginHorizontal :10,
      marginRight : 10,
      margin:10,
      flexDirection: 'row'
    },
    column2: {
      width: '50%',
    },
    column3: {
      width: '33%',
      flexDirection: 'row'
    },
    tableCol5: {
      width: '100%',
      flexDirection: 'row'
    },
    tableCol50: {
      width: '50%',
      flexDirection: 'row'
    },
    tableCol10 : {
      width:100
    },
    tableCol20 : {
      width:200
    },
    tableCol30 : {
      width:300
    },
    contentfontSize: {
      fontSize: 10
    },
    hrLIne : {
      borderBottom : 1 ,
    },
    hrLIneTop : {
      borderTop : 1 ,
    },
    borderAll : {
      border : 1,
      margin : 10
    },
    rightStart : {
      alignSelf : 'flex-end'
    }
  });
  //console.log(pathname[2]);
  const [pruchaseId, setpruchaseId] = useState(pathname[2]);
  const [partyName, setpartyName] = useState();
  const [partyAddress, setpartyAddress] = useState();
  const [partyGSTNo, setpartyGSTNo] = useState();
  const [rowsData, setRowsData] = useState([]);
  const [invoiceDate, setinvoiceDate] = useState();
  const [invoiceNo, setinvoiceNo] = useState();
  const [subTotal, setSubTotal] = useState();
  const [gstAmount, setgstAmount] = useState();
  const [grandTotal, setgrandTotal] = useState();
  const [partyType, setPartyType] = useState();
  
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = `Bearer ` + localStorage.getItem('token');
  const config = {
    headers: {
      'Authorization': token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
  };
  useEffect( async () => {
    axios.get(baseURL + "purchaseinwards/" + pruchaseId , config)
      .then(async function (data) {
        console.log(data.data.message)
        if (data.data.statuscode === 200) {
          setRowsData(data.data.message.inwardData);
          setpartyName(data.data.message.partyName);
          setpartyAddress(data.data.message.partyAddress);
          setPartyType(data.data.message.partyType);
          setpartyGSTNo(data.data.message.partyGSTNo);
          setinvoiceDate(data.data.message.purchaseInwardDate);
          setinvoiceNo(data.data.message.inwardNo);
          setSubTotal(data.data.message.subTotal);
          setgstAmount(data.data.message.gstAmount);
          setgrandTotal(data.data.message.grandTotal);

        //    var uniquesizesd = Object.values(data.data.dcDataDetails.reduce((hash, item) => {
        //     if (!hash[item.plateSizeName]) {
        //         hash[item.plateSizeName] = { plateSizeName: item.plateSizeName, quantity: 0 };
        //     }
        //     hash[item.plateSizeName].quantity += item.quantity;
        //     return hash;
        // }, {}));

        // setuniquesizes(uniquesizesd);
        
        }
       })

  }, []);

  return (

    <Document>
      <Page size="A4">
        <View>
          <View>
            <Image src={Dynanai} width="100%" alt="Dnyanai Logo" >  </Image>
          </View>
          <View>
            <Text style={[styles.textCenter, styles.headerTitle]}> Purchase Inward </Text>
          </View>
          <View style={styles.borderAll}>
          <View style={styles.column1}>
          <View  style={[styles.column2, styles.textLeft, styles.contentfontSize]} > 
            <Text>To : {partyName}</Text>
            <Text>Address :- {partyAddress}</Text>
            <Text>Party Type:  {partyType}</Text>
            <Text> GSTIN :- {partyGSTNo}</Text>
            </View>
            <View  style={[styles.column2, styles.textRight, styles.contentfontSize]} >
            <Text > Date :  {moment(invoiceDate).format('DD-MM-YYYY')} </Text>
            <Text > Purchase Inward No: {invoiceNo} </Text>
            </View>
            </View>
         

          <View style={[styles.tableCol5, styles.hrLIne, styles.hrLIneTop]}>
            <Text style={[styles.headerTitle, styles.tableCol10, styles.textCenter]}> Sr. No. </Text>
            <Text style={[styles.headerTitle, styles.tableCol30, styles.textCenter]}> Date </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Plate Size </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Plate Name </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Quantity </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Rate </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Amount </Text>
          </View>

          {
           
          rowsData.map((x, i) =>
            
           <View style={[styles.tableCol5, styles.hrLIne, styles.contentfontSize]}>
            <Text style={[styles.contentfontSize, styles.tableCol10, styles.textCenter, styles.contentfontSize]}> {i+1} </Text>
            <Text style={[styles.headerTitle, styles.tableCol30, styles.textCenter]}> {moment(invoiceDate).format('DD-MM-YYYY')} </Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.plateSizeName} mm</Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.plateName} </Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.quantity} </Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.rate} </Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.amount} </Text>
          </View> )}
          <View style={[styles.tableCol5, styles.hrLIne]}>
                <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  Total
                </Text>
                <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  {subTotal}
                </Text>
          </View>
          <View style={[styles.tableCol5, styles.hrLIne]}>
                <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  CGST @ 9%
                </Text>
                <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  {gstAmount/2}
                </Text>
          </View>
          <View style={[styles.tableCol5, styles.hrLIne]}>
                <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  SGST @ 9%
                </Text>
                <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  {gstAmount/2}
                </Text>
          </View>
          <View style={[styles.tableCol5, styles.hrLIne]}>
                <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  Grand Total
                </Text>
                <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  {grandTotal}
                </Text>
          </View>
      <View style={[styles.rightStart]}>
          <View style={[styles.hrLIne, styles.column3]}>
              <Text style={[styles.textCenter, styles.contentfontSize]}> Plate Size Details </Text>            
            </View> 
          </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PurchaseInwardPrint