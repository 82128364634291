import React , { useEffect}from "react";
import { Container, Row, Col } from "react-bootstrap";
import TopNavbar from '../navbar/navbar'; 
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from "axios";

function  Suppliers() {
const{useState}=React;

const[inputs,setinputs]=useState({
  udfId : "",
  suppName: "",
  suppPhone: "",
  suppEmail: "",
  suppGstIN: "",
  fullAddress : "",
  suppPincode : "",
  suppBlStateCode : "27",
  suppCity : "",
  suppBlState : "",
  contactPerson : "",
  mobileNumber : "",
  allSupplierList : [],
  
Message : "",

});
// const navigate = useNavigate();

const[warnsuppCode,setwarnsuppCode]=useState(false);
const[warnsuppName,setwarnsuppName]=useState(false);
const[warnsuppPhone,setwarnsuppPhone]=useState(false);
const[warnsuppEmail,setwarnsuppEmail]=useState(false);
const[warnsuppGstIN,setwarnsuppGstIN]=useState(false);
const[warnfullAddress,setwarnfullAddress]=useState(false);
const[warnsuppPincode,setwarnsuppPincode]=useState(false);
const[warnsuppBlState,setwarnsuppBlState]=useState(false);
const[warncontactPerson,setwarncontactPerson]=useState(false);
const[warnmobileNumber,setwarnmobileNumber]=useState(false);
const[warnsuppBlStateCode,setwarnsuppBlStateCode]=useState(false);
const[warnsuppCity, setwarnsuppCity]=useState(false);
const [allSupplierList, setallSupplierList] = useState();
const[updateMethod, setupdateMethod]=useState(false);
const[submitButtonName, setsubmitButtonName]=useState("Create New Suppliers");
const[supplierId, setsupplierId]=useState();

const[danger,setdanger]=useState(true);
const[Message,setMessage]=useState(false);

const inputEvent= (event)=>{
const name=event.target.name;
const value=event.target.value;
if(name === "username"){
if(value.length>0){
setdanger(true);
}
}
setinputs((lastValue)=>{
return{
...lastValue,
[name]:value
}
});
};

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

const suppCode = inputs.suppCode;
const suppName = inputs.suppName;
const suppGstIN = inputs.suppGstIN;     
const suppEmail = inputs.suppEmail;
const suppPhone = inputs.suppPhone;   
const fullAddress = inputs.fullAddress;
const suppCity = inputs.suppCity;
const suppPincode = inputs.suppPincode;
const suppBlStateCode = inputs.suppBlStateCode;        
const suppBlState = inputs.suppBlState;
const contactPerson = inputs.contactPerson;
const mobileNumber = inputs.mobileNumber;
const createdBy = localStorage.getItem('userId');

const bodyParameters = {
suppCode : suppCode,
suppName:suppName,
suppGstIN:suppGstIN,
suppEmail:suppEmail,
suppPhone:suppPhone,
fullAddress :fullAddress,
suppCity :suppCity,
suppPincode:suppPincode,
suppBlStateCode : suppBlStateCode,
suppBlState : suppBlState,
contactPerson :contactPerson,
mobileNumber : mobileNumber,
createdBy : createdBy,
activeStatus : "1",

};


const submitForm = async (event) =>{
        console.log(localStorage.getItem('userId'));
    event.preventDefault();
setMessage("");
setwarnsuppCode(false);
setwarnsuppName(false);
setwarnsuppPhone(false);
setwarnsuppGstIN(false);
setwarnsuppEmail(false);
setwarnsuppPincode(false);
setwarnsuppBlStateCode(false);
setwarnsuppCity(false);
setwarnfullAddress(false);
setwarnsuppBlState(false);
setwarncontactPerson(false);
setwarnmobileNumber(false);
if(suppCode === "" ){ setwarnsuppCode(true); } 
if(suppName === "" ){ setwarnsuppName(true); } 
if(suppEmail === "" ){ setwarnsuppEmail(true); } 
if(suppPhone === "" ){ setwarnsuppPhone(true); } 
if(suppGstIN === "" ){ setwarnsuppGstIN(true); } 
if( suppCode !=="" && suppName !== "" && suppEmail !== ""){
  
  if (updateMethod) {
    axios
    .put(baseURL +"suppliers/"+ supplierId,  
     bodyParameters, config)
    .then(data =>{
          console.log("data "+ data + " statuscode "+ data.data.statuscode);
          if(data.data.statuscode === "200"){     
          // window.location.href ='/suppliers';  
          clearAllInputs();
          getSuppliers();
          setsupplierId("");
          setupdateMethod(false);
          setsubmitButtonName("Create New suppliers");
        } 
      })
    .catch(error => console.log(error));
  } else {    
  axios
  .post(baseURL +"suppliers/register",  
   bodyParameters, config)
  .then(data =>{
        console.log("data "+ data + " statuscode "+ data.data.statuscode);
        if(data.data.statuscode === "200"){     
        // window.location.href ='/suppliers';  
        clearAllInputs();
        getSuppliers();

      } 
    })
  .catch(error => console.log(error));
  }  
}
}      

const getSuppliers = async () =>{
  const res = await axios.get(baseURL + "suppliers/" , config)
  const array = res.data.message;
  array.map(function(e){
    e.clickEvent = () => testClickEvent( e.id);
});
setallSupplierList(array) ;
}
  
useEffect(() => {
  getSuppliers();
}, [])

function clearData(){
        clearAllInputs();     
        setsupplierId("");
        setupdateMethod(false);
        setsubmitButtonName("Create New suppliers");
}

function clearAllInputs(){
  setinputs(()=>{
    return{
    suppPhone:"",
    suppName : "",
    suppEmail: "",      
    suppCode: "",
    fullAddress : "",
    suppBlState: "",      
    suppBlStateCode: "",
    suppCity : "",
    suppPincode: "",      
    suppGstIN: "",
    contactPerson: "",
    mobileNumber: ""
    }
    });
}


function testClickEvent(param) {
  clearAllInputs();
  axios.get(baseURL + "suppliers/" + param , config).then(
    data =>{
    setinputs(()=>{
      return{
      suppPhone:data.data.message.suppPhone,
      suppName : data.data.message.suppName,
      suppEmail: data.data.message.suppEmail,      
      suppCode: data.data.message.suppCode,
      fullAddress : data.data.message.fullAddress,
      suppBlState: data.data.message.suppBlState,      
      suppBlStateCode: data.data.message.suppBlStateCode,
      suppCity : data.data.message.suppCity,
      suppPincode: data.data.message.suppPincode,      
      suppGstIN: data.data.message.suppGstIN,
      contactPerson: data.data.message.contactPerson,
      mobileNumber: data.data.message.mobileNumber
      }
      });
      setsupplierId( data.data.message.id);
      setupdateMethod(true);
      setsubmitButtonName("Update Supplier Data");

  })
  .catch();  
}

const data = () => {

        return {
columns: [
        {
          label: 'Sr. No.',
          field: 'id',
          width: 75,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Sr. No.',
          },
        },
        {
          label: 'Supplier Name',
          field: 'suppName',
          width: 270,
        },
        {
          label: 'Supplier Phone',
          field: 'suppPhone',
          width: 200,
        },{
          label: 'Supplier Email',
          field: 'suppEmail',
          width: 200,
        },        
      ],
      rows : allSupplierList,

    }
}
    return(
    <>
    <TopNavbar />
    <Container fluid>  
        <Row>
        <Col xs={12} md={4} className="rightBorder">
                <h4 className="text-center"> Supplier List</h4>
        <CDBContainer>
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                className= "fontsize-14 minHeight"
                        striped
                        bordered
                        hover
                        scrollX
                        scrollY
                        minHeight="300xp"
                        maxHeight="300xp"
                        data={data()}
                        materialSearch
                        fullPagination
                        responsiveSm
                        entriesOptions={[10, 20, 25, 50, 100, 1000]}
                        entries={10}
                        pagesAmount={4}
                  />
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
        </Col>
        <Col xs={12} md={8} >
        <h4 className="text-center"> {submitButtonName }</h4> 
         <form autoComplete="off" onSubmit={submitForm}>
        <Row>
            <Col xs={4} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Supplier Code </label>
                                <input className={` ${warnsuppCode ? "warning" : "form-control" }`} type="text" placeholder="Enter Supplier Code " autoComplete="off" name="suppCode" value={inputs.suppCode ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Supplier Code.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={8} md={8}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label > Supplier Name </label>
                                <input className={` ${warnsuppName ? "warning" : "form-control" }`} type="text" placeholder="Enter Supplier Name" autoComplete="off" name="suppName" value={inputs.suppName ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Supplier Name.</p>
                            </div>                         
                    </div>
            </Col>
        </Row> 
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> GST Number</label>
                                <input className={` ${warnsuppGstIN ? "warning" : "form-control" }`} type="text" placeholder="12ABCD3456E1ZF" autoComplete="off" name="suppGstIN" value={inputs.suppGstIN ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Enter GST Number.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> Phone Number</label>
                                <input className={` ${warnsuppPhone ? "warning" : "form-control" }`} type="text" placeholder="+911234567890" autoComplete="off" name="suppPhone" value={inputs.suppPhone ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a Phone Number.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> Email Address</label>
                                <input className={` ${warnsuppEmail ? "warning" : "form-control" }`} type="text" placeholder="dnyanaictp@gmail.com" autoComplete="off" name="suppEmail" value={inputs.suppEmail ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Email Address.</p>
                            </div>
                    </div>
            </Col> 
        </Row> 
        <Row>
            <Col xs={12} md={12}>                    
                    <div className="right-side">
                            <div className="input_text">
                              <label> Supplier Address</label>
                                <input className={` ${warnfullAddress ? "warning" : "form-control" }`} type="text" placeholder="No. 249, Aanand House, Shaniwar Peth, near Mutheshwar Chowk, Pune, Maharashtra 411030" autoComplete="off" name="fullAddress" value={inputs.fullAddress ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Mobile.</p>
                            </div>                         
                    </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> City </label>
                                <input className={` ${warnsuppCity ? "warning" : "form-control" }`} type="text" placeholder="Pune" autoComplete="off" name="suppCity" value={inputs.suppCity ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter City.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={2}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> Pincode</label>
                                <input className={` ${warnsuppPincode ? "warning" : "form-control" }`} type="text" placeholder="411001" autoComplete="off" name="suppPincode" value={inputs.suppPincode ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter Pincode.</p>
                            </div>
                    </div>
            </Col> 
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> State </label>
                                <input className={` ${warnsuppBlState ? "warning" : "form-control" }`} type="text" placeholder="Maharashtra" autoComplete="off" name="suppBlState" value={inputs.suppBlState ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter State</p>
                            </div>
                    </div>
            </Col>
            <Col xs={12} md={2}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label> State Code </label>
                              <input className={` ${warnsuppBlStateCode ? "warning" : "form-control" }`} type="text" placeholder="27" autoComplete="off" name="suppBlStateCode" value={inputs.suppBlStateCode ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a First Name.</p>
                            </div>
                    </div>
            </Col>  
             
        </Row>
        <Row>
            <Col xs={12} md={8}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> Contact Person</label>
                                <input className={` ${warncontactPerson ? "warning" : "form-control" }`} type="text" placeholder="Enter Contact Person Name" autoComplete="off" name="contactPerson" value={inputs.contactPerson ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Contact Person Name.</p>
                            </div>                         
                    </div>
            </Col>
            <Col xs={12} md={4}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label> Contact Person Mobile Number</label>
                                <input className={` ${warnmobileNumber ? "warning" : "form-control" }`} type="text" placeholder="Mobile Number" autoComplete="off" name="mobileNumber" value={inputs.mobileNumber ||  '' } onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please Contact Person Mobile Number.</p>
                            </div>                         
                    </div>
            </Col>
                       
        </Row>
        <Row>
                <Col>
                <button type="button" className="btn btn-warning" onClick={clearData}> Clear </button>
                </Col>
                <Col>
                <button type="submit" className="btn btn-primary">{submitButtonName}</button>                
                </Col>
        </Row>
        {Message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {Message}
                        </div>
                        </div>
                    )}         
        </form> 
        </Col>
        </Row>
        </Container>



    </>
    );
}
export default Suppliers;