import React , { useEffect}from "react";
import { Container, Row, Col } from "react-bootstrap";
import TopNavbar from '../navbar/navbar'; 
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from "axios";
import moment from 'moment';
import { Link } from 'react-router-dom';

function  Taxreport() {
        
const{useState}=React;

const[fromdate,setfromdate]=useState((moment(new Date())).format('YYYY-MM-DD'));
const[todate,settodate]=useState((moment(new Date())).format('YYYY-MM-DD'));
const[reporttype,setreporttype]=useState("1");

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 
                'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

const eventhandleChangetype = async (e) => {
        setreporttype(e.target.value);
      }

const getreport = async (event) =>{ 
   const bodyParameters={
    fromDate:fromdate,
    toDate:todate
    }   
//event.preventDefault();
let callurl="";
if(reporttype === "1")
{
        callurl="report/generatereport/";
}
else if(reporttype === "2")
{
        callurl="report/generatecashreport/";    
} 
else if(reporttype === "3")
{
        callurl="report/generateTotalSalesReport/";      
}
axios.post(baseURL+callurl, bodyParameters, config)
.then((data) => {
   let url
        if (data.data.statuscode === '200' && data.data.message) {
           url= data.data.message;
        }
        else
        {
          url= data.data.statuscode
        }

               const link = document.createElement('a');
                link.href = baseURL + "uploads/" + url; 
                link.target = '_blank'; 

                // Open the file for viewing
                window.open(link.href, link.target);

         })
.catch(error => console.log(error));
}

const containerStyle = {
        border: '2px solid #999999', // Specify border properties here
        padding: '20px', // You can also add other styles as needed
          }

    return(
    <>
    <TopNavbar />
    <Container style={containerStyle}>  
        <Row>
       <Col xs={12} md={8} >
        <h4 className="text-center"></h4> 
        <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <h4 className="text-center">SALES REPORT</h4> 
                             </div>                         
                    </div>
            </Col>
            </Row>
            <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > Report Type </label>
                                <select className="form-control" required="required" name="reporttype" value={reporttype} onChange={eventhandleChangetype}>
                                        <option value="1">Tax Report</option>
                                        <option value="2">Cash Report</option>
                                        <option value="3">Total Sales Report</option>
                                </select>
                            </div>                         
                    </div>
            </Col>
            </Row>
        <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                                <label > From Date </label>
                                <input className="form-control" type="date" name="fromdate" value={fromdate}  onChange={e => setfromdate(e.target.value)} />
                            </div>                         
                    </div>
            </Col>
            </Row>
            <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                    <div className="right-side">
                            <div className="input_text">
                            <label > To Date </label>
                            <input className="form-control" type="date" name="todate" value={todate} onChange={e => settodate(e.target.value)} />
                            </div>                         
                    </div>
            </Col>
            </Row>
            <Row>
            <Col xs={6} md={6}>                    
            </Col>
            <Col xs={6} md={6}>                    
                   
            <div className="right-side">
                            <div className="input_text">
                            <label > Generate  </label>
                            <a className="btn2" type="button" onClick={getreport} 
                             target="_blank"  rel="noreferrer" >&nbsp;&nbsp;<i className="fa fa-download"></i></a>
                            </div>                         
                    </div>


                    {/* <a className="btn2" type="button" onClick={getreport} 
                    target="_blank"  rel="noreferrer" ><i className="fa fa-download"></i> Generate</a>                         */}
                   
            </Col>
            </Row> 
            </Col>
        </Row> 
      
       </Container></>
    );
}
export default Taxreport;