import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, Image, StyleSheet} from '@react-pdf/renderer';
import { Row, Col } from 'react-bootstrap'
import Dynanai from "../../assets/images/headerbar.jpg"
import axios from 'axios';
import moment from 'moment';

var pathname = window.location.pathname.split('/');
var y=0;

function InvoicePrint() {

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    headerTitle: {
        fontWeight: 'bold',
        fontSize: 22,
    },
    textCenter: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: "bold"
    },
    textRight: {
      textAlign: 'right',
    },
    textLeft: {
      textAlign: 'left'
    },
    textBold : {
      fontWeight : 'bold',
    },
    column1: {
      width: '96%',
      marginHorizontal :10,
      marginRight : 10,
      margin:10,
      flexDirection: 'row'
    },
    column2: {
      width: '50%',
    },
    column3: {
      width: '33%',
      flexDirection: 'row'
    },
    tableCol5: {
      width: '100%',
      flexDirection: 'row'
    },
    tableCol50: {
      width: '50%',
      flexDirection: 'row',
    },
    tableCol: {
      width:50,
      padding: '1px',
      borderRight : 1,
      borderLeft : 1,
    },
    tableCol10 : {
      width:100,
      padding: '6px',
      borderRight : 1,
      borderLeft : 1,
    },
    tableCol20 : {
      width:200,
      padding: '6px',
      borderRight : 1,
      borderLeft : 1,
    },
    tableCol30 : {
      width:300,
      padding: '6px',
      borderRight : 1,
      borderLeft : 1,
    },
    tableCol40 : {
      width:400,
      padding: '5px',
      borderRight : 1,
      borderLeft : 1,
    },
    tableCol60 : {
      width:358,
      padding: '5px',
      borderRight : 1,
      borderLeft : 1,
    },
    contentfontSize: {
      fontSize: 11
    },
    hrLIne : {
      borderBottom : 1 ,
      
    },
    hrLIneTop : {
      borderTop : 1 ,
    },
    borderAll : {
      border : 1,
      margin : 10
    },
    rightStart : {
      alignSelf : 'flex-end'
    },
    bolderText : {
      fontSize: 15,
      fontWeight : 700
    },
    empty :{
      borderRight : 1,
      borderLeft : 1,
    }, 
    smallerFont : {
      fontSize : '10px'
    }
  });
  
  const [invoiceId, setinvoiceId] = useState(pathname[2]);
  const [partyName, setpartyName] = useState();
  const [partyAddress, setpartyAddress] = useState();
  const [partyGSTNo, setpartyGSTNo] = useState();
  const [rowsData, setRowsData] = useState([]);
  const [invoiceDate, setinvoiceDate] = useState();
  const [invoiceNo, setinvoiceNo] = useState();
  const [subTotal, setSubTotal] = useState();
  const [gstAmount, setgstAmount] = useState();
  const [grandTotal, setgrandTotal] = useState();
  const [email, setEmail] = useState();
  const [gstPercent, setgstPercent] = useState();
  const [challandates, setchallandates] = useState();
  
  
  
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = `Bearer ` + localStorage.getItem('token');
  const config = {
    headers: {
      'Authorization': token,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
    }
  };
  useEffect( async () => {
    axios.get(baseURL + "cashinvoices/" + invoiceId , config)
      .then(async function (data) {
        if (data.data.statuscode === 200) {
          setRowsData(data.data.message.invoiceData);
          setpartyName(data.data.message.partyName);
          setpartyAddress(data.data.message.partyAddress);
          setpartyGSTNo(data.data.message.partyGSTNo);
          setinvoiceDate(data.data.message.invoiceDate);
          setinvoiceNo(data.data.message.invoiceNo);
          setSubTotal(data.data.message.subTotal);
          setgstAmount(data.data.message.gstAmount);
          setgrandTotal(data.data.message.grandTotal);
          setgstPercent(data.data.message.gstPercentage);
          setchallandates(data.data.message.challandates);
          

        //    var uniquesizesd = Object.values(data.data.dcDataDetails.reduce((hash, item) => {
        //     if (!hash[item.plateSizeName]) {
        //         hash[item.plateSizeName] = { plateSizeName: item.plateSizeName, quantity: 0 };
        //     }
        //     hash[item.plateSizeName].quantity += item.quantity;
        //     return hash;
        // }, {}));

        // setuniquesizes(uniquesizesd);
        
        }
       })

  }, []);

  return (

    <Document>
      <Page size="A4">
        <View>
          <View>
            <Image src={Dynanai} width="100%" alt="Dnyanai Logo" >  </Image>
          </View>
          <View>
            <Text style={[styles.textCenter, styles.headerTitle]}> Cash Invoice </Text>
          </View>
          <View style={styles.borderAll}>
            <View style={styles.column1}>
            <View  style={[styles.column2, styles.textLeft, styles.contentfontSize]} > 
            <Text style={{paddingBottom: '5px'}}>To : {partyName}</Text>
            <Text style={{paddingBottom: '5px'}}>Address : {partyAddress}</Text>
            <Text style={{paddingBottom: '5px'}}>Email:  {email}</Text>
            {/* <Text style={{paddingBottom: '5px'}}>GSTIN :- {partyGSTNo}</Text> */}
            <Text style={{paddingBottom: '5px'}}>Challan Date :- {challandates}</Text>
            </View>
            <View  style={[styles.column2, styles.textRight, styles.contentfontSize]} >
            <View style={styles.column1}>
              <View  style={[styles.column2, styles.textLeft, styles.contentfontSize]} >
                <Text></Text>
              </View>
              <View  style={[styles.column2, styles.textLeft, styles.contentfontSize]} >
                <Text>Invoice Date : {moment(invoiceDate).format('DD.MM.yy')} </Text>
              </View>
            </View>
            <View style={styles.column1}>
              <View  style={[styles.column2, styles.textLeft, styles.contentfontSize]} >
                <Text></Text>
              </View>
              
               <View  style={[styles.column2, styles.textLeft, styles.contentfontSize]} >
                <Text>Bill No : {invoiceNo} </Text>
              </View>
            </View>
            </View>
            </View>
         

          <View style={[styles.tableCol5, styles.hrLIne, styles.hrLIneTop]}>
            <Text style={[styles.headerTitle, styles.tableCol, styles.textCenter]}> Sr. No. </Text>
            {/* <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Date </Text> */}
            <Text style={[styles.headerTitle, styles.tableCol30, styles.textCenter]}> Plate Size </Text>
            {/* <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> HSN </Text> */}
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Quantity </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Rate </Text>
            <Text style={[styles.headerTitle, styles.tableCol20, styles.textCenter]}> Amount </Text>
          </View>

          {
           
          rowsData.map((x, i) =>
            
           <View style={[styles.tableCol5, styles.hrLIne, styles.contentfontSize]}>
            <Text style={[styles.contentfontSize, styles.tableCol, styles.textCenter, styles.contentfontSize]}> {i+1} </Text>
            {/* <Text style={[styles.headerTitle, styles.tableCol20, styles.contentfontSize]}> {invoiceDate} </Text> */}
            <Text style={[styles.tableCol30, styles.textCenter, styles.contentfontSize]}> {x.plateSizeName} mm</Text>
            {/* <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.hsn} </Text> */}
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {x.saleQuantity} </Text>
                  
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {new Intl.NumberFormat("en-IN" ,{ style: 'currency',currency: 'INR'}).format(x.saleRate).replace('₹', "")} </Text>
            <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> {new Intl.NumberFormat("en-IN" ,{ style: 'currency',currency: 'INR'}).format(x.saleAmount).replace('₹', "")} </Text>
          </View> )}
          <View style={[styles.tableCol5,]}>
            <Text style={[styles.tableCol, styles.empty]}> </Text>
            {/* <Text style={[styles.tableCol20, styles.empty]}> </Text> */}
            <Text style={[styles.tableCol30, styles.empty]}> </Text>
            {/* <Text style={[styles.tableCol20, styles.empty]}> </Text> */}
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
          </View>
          <View style={[styles.tableCol5,]}>
            <Text style={[styles.tableCol, styles.empty]}> </Text>
            {/* <Text style={[styles.tableCol20, styles.empty]}> </Text> */}
            <Text style={[styles.tableCol30, styles.empty]}> </Text>
            {/* <Text style={[styles.tableCol20, styles.empty]}> </Text> */}
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
          </View>
          <View style={[styles.tableCol5, styles.hrLIne]}>
            <Text style={[styles.tableCol, styles.empty]}> </Text>
            {/* <Text style={[styles.tableCol20, styles.empty]}> </Text> */}
            <Text style={[styles.tableCol30, styles.empty, styles.smallerFont]}>
              {/* Bank Details
            DNYANAI CTPCC 
            A/c No.:- 71910500000024
            IFSCode.: - BARB0DBBHPE
            Bank Of Baroda, Bhavani Peth, Pune */}
            </Text>
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
            {/* <Text style={[styles.tableCol20, styles.empty]}> </Text> */}
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
            <Text style={[styles.tableCol20, styles.empty]}> </Text>
          </View>
          
        <View style={styles.tableCol5}>
          <View  style={[styles.tableCol60, styles.textLeft, styles.smallerFont, styles.hrLIne]} > 
            {/* <Text>Declaration: We hereby certify that our registration Certificate under the Goods &
Service Tax Act is in force 1st July 2017 On the date on which the sale of the
goods specified in this tax invoice is made by us & that the transaction of sale
covered by this tax invoice has been effected by us & it shall be accounted for in
the turnover of sale while filling of return and the due tax if any payable on this sale
has been paid or shall be paid.</Text> */}
          </View>
          <View  style={[styles.column2, styles.textRight, styles.contentfontSize]} >
            <View style={[styles.tableCol5, styles.hrLIne]}>
                  <Text style={[styles.tableCol40, styles.textCenter, styles.contentfontSize]}> 
                    Total
                  </Text>
                  <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                  {new Intl.NumberFormat("en-IN" ,{ style: 'currency',currency: 'INR'}).format(subTotal).replace('₹', "")}
                  </Text>
            </View>
           
           
         
          </View>
        </View>
        <View style={styles.tableCol5}>
          <View  style={[styles.tableCol60, styles.textLeft, styles.smallerFont, styles.hrLIne]} > 
            {/* <Text>GSTIN/UIN : 27AAJFD1734F1ZN wef : 01.07.2017</Text> */}
          </View>
          <View  style={[styles.column2, styles.textRight, styles.contentfontSize]} >
            <View style={[styles.tableCol5, styles.hrLIne]}>
                  <Text style={[styles.tableCol40, styles.textCenter, styles.bolderText]}> 
                    Grand Total
                  </Text>
                  <Text style={[styles.tableCol20, styles.textCenter, styles.contentfontSize]}> 
                    {new Intl.NumberFormat("en-IN" ,{ style: 'currency',currency: 'INR'}).format(grandTotal).replace('₹', "")}
                  </Text>
            </View>
          </View>
        </View>
        <View style={styles.column1}>
            <View  style={[styles.column2, styles.textLeft, styles.contentfontSize]} > 
            <Text> </Text>
            <Text> </Text>
            <Text> </Text>
            <Text>Receiver's Signature</Text>
            </View>
            <View  style={[styles.column2, styles.textRight, styles.contentfontSize]} > 
            <Text> </Text>
            <Text> </Text>
            <Text> </Text>
            <Text>For Dnyanai CTP</Text>
            </View>
          </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default InvoicePrint