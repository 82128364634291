import React from 'react'
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import Container from 'react-bootstrap/Container';
import PurchaseInwardPrint from './purchaseinwardprint';

function PurchasePrintPage() {
  return (
    <>
    <Container>
    <PDFViewer height={768} style={{width:'100%'} }>
    <PurchaseInwardPrint />
  </PDFViewer>
  </Container>
  </>
  )
}

export default PurchasePrintPage