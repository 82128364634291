import React from 'react'
//import { Col, Row } from 'react-bootstrap'

// const PurchaseItemAdd = () => {
//   return (
//     <>
//     <Row>
//         <Col>
//         1
//         </Col>
//         <Col>
//         <select>
//             <option> </option>
//         </select>
//         </Col>
//         <Col>
//         <select>
//             <option> </option>
//         </select>
//         </Col>
//         <Col>
//         <input type="text" /> 
//         </Col>
//         <Col>
//         <input type="text" /> 
//         </Col>
//         <Col>
//         <input type="text" readOnly/> 
//         </Col>
//     </Row>
      
//     </>
//   )
// }

// export default PurchaseItemAdd


function PurchaseItemAdd({rowsData,deleteTableRows,handleChange,ptype,eventhandleChangePlateName,eventhandleChangePlateSize}) {
    return(
        rowsData.map((data, index)=>{
            const {plates,sizes,quantity,rate,amount, srno}= data;
          //  alert("dddfd : "+JSON.stringify(sizes));
            return(
                <tr key={index}>
                    {/* <td className="text-end">{srno } </td> */}
                <td>
                 {ptype == 'buyer'? 
                <select className="form-control" name="plateSizeName" onChange={(evnt)=>(eventhandleChangePlateSize(index, evnt))}>
                   { sizes.map((item, index )=>( 
                    // <option value=""></option>
                    
                    <option value={item.plateSizeId}>{item.plateSize}</option>
                    ))}
              </select>
              : 
              <select className="form-control" name="plateSizeName" onChange={(evnt)=>(eventhandleChangePlateSize(index, evnt))}>
                   { sizes.map((item, index )=>( 
                    // <option value=""></option>
                <option value={item.plateSizeId}>{item.plateSizeName}</option>
                    ))}
              </select>
               }
         </td>
                <td>
                <select className="form-control" name="plateName" onChange={(evnt)=>(eventhandleChangePlateName(index, evnt))}>
                 <option value="1" selected={(data.plateName === "Thermal") ? true : false}>Thermal</option>
                 <option value="2" selected={(data.plateName === "CTPC") ? true : false}>CTPC</option>
                {/* { sizes.map((item, index )=>( 
 <option value={item.id}>{item.plateSizeCode}  - {item.plateSizeName}</option>
                    ))} */}
        </select> </td>
                <td>
                    <input type="text" value={quantity}  onChange={(evnt)=>(handleChange(index, evnt))} name="quantity" className="form-control text-end" /> 
               </td>
               <td>
                    <input type="text" value={rate}  onChange={(evnt)=>(handleChange(index, evnt))} name="rate" className="form-control text-end" /> 
               </td>
               <td>
                    <input type="text" value={amount}  onChange={(evnt)=>(handleChange(index, evnt))} name="amount" className="form-control text-end" /> 
               </td>
                <td><a className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>x</a></td>
            </tr>
            )
        })
   
    )
    
}
export default PurchaseItemAdd