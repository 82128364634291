import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from './login/signin';
import Signup from './login/signup';
import Logout from './login/logout';
import Dashboard from './pages/dashboard/dashbaord';
import Customers from './pages/customers/customers';
import Suppliers from './pages/supplier/supplier';
import Items from './pages/items/items';
import Purchaseinward from './pages/purchase/purchaseinward';
import PartyPayment from './pages/partypayment/partypayment';
import PartyReceipt from './pages/partyreceipt/partyreceipt';
import DeliverChallan from './pages/deliverchallan/deliverchallan';
import DeliveryChallanList from './pages/deliverchallan/deliverychallanlist';
import SalesInvoices from './pages/sales/salesinvoices';
import CreateInvoice from './pages/sales/createinvoice';
import DcPrintPage from './pages/deliverchallan/dcprintpage';
import Salesinvoicelist from './pages/sales/salesinvoicelist';
import InvoicePrint from './pages/sales/invoiceprintpage';
import InvoicedcPrint from './pages/sales/invoicedcprintpage';
import Purchaseinwardlist from './pages/purchase/purchaseinwardlist';
import PurchasePrintPage from './pages/purchase/purchaseinwardprintpage';

import CashInvoices from './pages/cashinvoice/cashinvoicelist';
import CreatecashInvoice from './pages/cashinvoice/createcashinvoice';
import CashInvoicePrint from './pages/cashinvoice/cashinvoiceprintpage';
import CashInvoicedcPrint from './pages/cashinvoice/cashinvoicedcprintpage';
import TaxReport from './pages/reports/taxreport';
import CustomerLedger from './pages/reports/customerLedger';


// import ProtectedRoute from './login/ProtectedRoute';s
// import Stockregister from './pages/stock/stockregister';



function App() {

  // if(!localStorage.getItem('token')  ) {
  //   if(window.location.pathname !== "/signin"){
  //     window.location.href = '/signin';
  //   }
  //   } 

  return (
    <BrowserRouter>
      <Routes>
       <Route path="/" element={<Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/logout" element={<Logout />}  />
        <Route path="/customers" element={<Customers />}  />
        <Route path="/suppliers" element={<Suppliers />}  />
        <Route path="/items" element={<Items />}  />
        <Route path="/purchaseinward" element={<Purchaseinward />}  />
        <Route path="/purchaseinward/:id" element={<Purchaseinward />}  /> 
        <Route path="/purchaseinwards" element={<Purchaseinwardlist />}  />
        <Route path="/partypayment" element={<PartyPayment />} />
        <Route path="/partyreceipt" element={<PartyReceipt />} />
        <Route path="/deliverychallan" element={<DeliverChallan />} />
        <Route path="/deliverychallans" element={<DeliveryChallanList />} />
        <Route path="/salesinvoices" element={<SalesInvoices />} />
        <Route path="/createsalesinvoice" element={<CreateInvoice />} />
        <Route path="/salesinvoicelist" element={<Salesinvoicelist />} />
        <Route path="/invoiceprint/:id" element={<InvoicePrint />} />
        <Route path="/purchaseinwardprint/:id" element={<PurchasePrintPage />} />
        <Route path="/dcprint/:id" element={<DcPrintPage />} />
        <Route path="/invoicedcprint/:id" element={<InvoicedcPrint/>} />

        <Route path="/cashinvoices" element={<CashInvoices/>} />
        <Route path="/createcashinvoice" element={<CreatecashInvoice />} />
        <Route path="/cashinvoiceprint/:id" element={<CashInvoicePrint />} />
        <Route path="/cashinvoicedcprint/:id" element={<CashInvoicedcPrint/>} />
        <Route path="/taxreport" element={<TaxReport/>} />
        <Route path="/customerLedger" element={<CustomerLedger/>} />
        
        {/* <Route path="/stockregister" element={<ProtectedRoute element={<Stockregister />} />} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default App