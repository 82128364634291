import React , { useEffect}from "react";
import { Container, Row, Col } from "react-bootstrap";
import TopNavbar from '../navbar/navbar'; 
import { CDBCard, CDBCardBody, CDBDataTable, CDBContainer } from 'cdbreact';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import moment from "moment/moment";

const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};
const deletePost = (params) => {
  console.log(params)
}


function Purchaseinwardlist() {
    const{useState}=React;

    const [show, setShow] = useState(false);
    const [allDcList, setallDcList] = useState();
    
    
    const getPurchaseInwards = async () =>{
        const res = await axios.get(baseURL + "purchaseinwards/" , config)
        const array = res.data.message; 
        console.log(res.data.message)

       array.map(object => {
            var action = (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  className="uil-trash-alt"
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: ".7em",
                    padding: ".5rem",
                    borderRadius: ".3rem",
                    background: "green",
                  }}
                 
                  // onClick={() => printDC(object.id)}
                >
                  <Link to={"../purchaseinwardprint/" + object.id} target="_blank" style={{textDecoration:"none" , color: '#fff'}} >Print</Link>
                  
                </div>
                {/* <div
                  className="uil-trash-alt"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    fontSize: ".7em",
                    padding: ".5rem",
                    borderRadius: ".3rem",
                    background: "#fb6262",
                  }}
                  // onClick={() => deletePost(object.id)}
                >
                  <Link to={"../purchaseinward/" + object.id} style={{textDecoration:"none" , color: '#fff'}}>Edit</Link>
                </div> */}
              </div>
            );
           
            return Object.assign(object, {action: action},{date : moment(object.purchaseInwardDate).format('DD-MM-YYYY')});
            });
        setallDcList(array) ;
      }
        
      useEffect(() => {
        getPurchaseInwards();
      }, [])
      
    const data = () => {

        return {
columns: [
        // {
        //   label: 'Sr. No.',
        //   field: 'id',
        //   width: 75,
        //   attributes: {
        //     'aria-controls': 'DataTable',
        //     'aria-label': 'Sr. No.',
        //   },
        // },
        {
          label: 'Customer Name',
          field: 'partyName',
          width: 270,
        },
        {
          label: 'Inward Number',
          field: 'inwardNo',
          width: 200,
        },{
          label: 'Inward Date',
          field: 'date',
          width: 200,
        },  
        {
            label: 'GST (%)',
            field: 'gstPercentage',
            width: 200,
          },  
          {
            label: 'Grand Total',
            field: 'grandTotal',
            width: 200,
          }, 
          {
            field: 'action',
            label: 'Action Button',
             width: 100,
            // render: (rowData) => (
            //   <button onClick={() => handleClick(rowData.id)}>Click me</button>
            // ),
          }
      ],
      rows : allDcList,

    }
}

  return (    
    <>
     <TopNavbar />
    <Container fluid>  
        <Row>
        <Col xs={12} md={12}>
                <h4 className="text-center"> Purchase Inward List</h4>
        <CDBContainer>
        
              <CDBCard>
                <CDBCardBody>
                  <CDBDataTable
                   className= "fontsize-14 minHeight"
                        striped
                        bordered
                        hover
                        scrollX
                        scrollY
                        minHeight="300px"
                        data={data()}
                        materialSearch
                        fullPagination
                        responsiveSm
                        entriesOptions={[10, 20, 25, 50, 100, 1000]}
                        entries={10}
                        pagesAmount={4}
                  />
                  
                </CDBCardBody>
              </CDBCard>
            </CDBContainer>
        </Col>
        </Row>

        <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            DC Details 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="pdfdownload" style={{padding : "30px;"}} >
        {/* <DcDownload dcNumber = {dcNumber} style={{padding : "30px;"}} /> */}
        </Modal.Body>
        <Modal.Footer>
          <button> Download DC </button>
        </Modal.Footer>
      </Modal>
        </Container>
    </>
  )
}

export default Purchaseinwardlist