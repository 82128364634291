import React from "react";
import TopNavbar from '../navbar/navbar';

function Dashboard() {
    return(
        <>
        <TopNavbar />
         <div className="container">
        <div className="content">
        <div> Welcome to Dnyanai CTP  </div>       
                   
        </div>
        </div>
      </>
    );
}
export default Dashboard;