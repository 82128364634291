import React from "react";
import "./signin.css";
import DnyanaiLogo from "../assets/images/dnyanailogo.jpg";
import AuthService from "../login/auth.service";

function Signin() {
const{useState}=React;

const[inputs,setinputs]=useState({
username:"",
password:"",
Message : "",

});
// const navigate = useNavigate();

const[warnusername,setwarnusername]=useState(false);
const[warnpass,setwarnpass]=useState(false);
const[danger,setdanger]=useState(true);
const[Message,setMessage]=useState(false);

const[eye,seteye]=useState(true);
const[pass,setpass]=useState("password");

const inputEvent= (event)=>{
const name=event.target.name;
const value=event.target.value;
if(name === "username"){
if(value.length>0){
setdanger(true);
}
}
setinputs((lastValue)=>{
return{
...lastValue,
[name]:value
}
});
};

const submitForm = async (event) =>{
    event.preventDefault();
setMessage("");
setwarnusername(false);
setwarnpass(false);
const username = inputs.username;
const password = inputs.password;
   await AuthService.login(username, password)
    if(localStorage.getItem('token')){     
        window.location.href ='/dashboard';  
    }
}
   const Eye=()=>{
    if(pass === "password"){
    setpass("text");
    seteye(false);
    }else{
    setpass("password");
    seteye(true);
    }
    }; 
      
    return(
    <>
        <div className="signincontainer" style={{
            'minHeight':'100vh',
            'display' : 'flex',
            'alignItems' : 'center',
            'justifyContent' : 'center'
    }}>
            <div className="card">
                <div className="form">
                    <div className="left-side ">
                     <img  src={DnyanaiLogo} alt="Logo" />
                    </div>

                    <div className="right-side">
                        <div className="register">
                            <p>Not a member? <a href="/signup">Register Now</a></p>
                        </div>

                        <div className="hello">
                            <h4>Welcome to Dnyanai CTP</h4>
                        </div>

                        <form onSubmit={submitForm}>

                            <div className="input_text">
                                <input className={` ${warnusername ? "warning" : "" }`} type="text" placeholder="Enter Username" autoComplete="off" name="username" value={inputs.username} onChange={inputEvent} />
                                <p className={` ${danger ? "danger" : "" }`}><i className="fa fa-warning"></i>Please enter a valid Username.</p>
                            </div>
                            <div className="input_text">
                                <input className={` ${warnpass ? "warning" : "" }`} type={pass} placeholder="Enter Password" autoComplete="off" name="password" value={inputs.password} onChange={inputEvent} />
                                <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i>
                            </div>
                            <div className="recovery">
                                <p> Recovery Password </p>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">Sign in</button>
                            </div>
                            {Message && (
                        <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {Message}
                        </div>
                        </div>
                    )}
                        </form>
                    </div>
                </div>
            </div>
        </div>



    </>
    );
}
export default Signin;