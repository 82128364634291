import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Plates from "./plates";
import TopNavbar from "../navbar/navbar";
import Sizes from "./sizes";

function Items() {

    return(
    <>    
    <TopNavbar />
    <Container fluid>  
        <Row>
            <Col>            
            <Plates />    
            </Col> 
            <Col>
            <Sizes />
            </Col>   
        </Row>
        </Container>



    </>
    );
}
export default Items;