import React , {useState,useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import TopNavbar from '../navbar/navbar';
import PurchaseItemAdd from './PurchaseItemAdd';
import Select from 'react-select';
import axios from "axios";
import "./purchaseinward.css";
import { useParams } from 'react-router-dom';
import moment from 'moment';

const Purchaseinward = () => {
    //subtotal and gst data
    const [srno, setSrno] =useState(1);
    const [purchaseInwardDate, setpurchaseInwardDate] =useState();
    const [platequantity, setplatequantity] =useState();
    const [subtotal, setsubtotal] =useState();
    const [gstAmount, setgstamount] =useState();
    const [gstPercentage, setgstPercentage] = useState(18);
    const[grandTotal, setGrandTotal] = useState();
    //Add more values
    const [rowsData, setRowsData] = useState([]);
    const [allPlatesList, setallPlatesList] = useState([]);
    const [allPlatesizeList, setallPlatesizeList] = useState([]);
    //set party type selected
    const [partyType, setpartyType] = useState();
    //data after selection of partyname
    const [selectOptions, setselectOptions] = useState([]);
    const [partyName, setpartyName] = useState();
    const [partyId, setpartyId] = useState();
    const [addresss, setaddresss] = useState('');
    const [gstnumber, setgstnumber] = useState('');
    const [otherdetails, setotherdetails] = useState([]);
    //data submission details
    const[updateMethod, setupdateMethod]=useState(false);
    const[submitButtonName, setsubmitButtonName]=useState("Submit");
    const createdBy = localStorage.getItem('userId');
    const [invoiceNo, setinvoiceNo] = useState();
    const [defaultdate, setdefaultdate] = useState(moment(new Date).format('YYYY-MM-DD'));
    //plate details for inwarddata save
    const [plateId, setplateId] = useState();
    const [plateName, setplateName] = useState();
    const [plateSizeId, setplateSizeId] = useState();
    const [plateSize, setplateSize] = useState();
    const [addButtonShow, setAddButtonShow] = useState(false);

    const params = useParams();

    useEffect(() => {
      if(params){
        axios.get(baseURL + "purchaseinwards/" + params.id , config)
      .then(async function (data) {
        if (data.data.statuscode === 200) {
          let rowData = data.data.message.inwardData.map((data) => {
            return {...data , sizes : [{plateSizeId: data.plateSizeId, plateSize:data.plateSizeName}]}
          })
        
          setRowsData(rowData)
          setpartyName(data.data.message.partyName);
          setaddresss(data.data.message.partyAddress);
          setpartyType(data.data.message.partyType);
          setgstnumber(data.data.message.partyGSTNo);
          setdefaultdate(moment(data.data.message.purchaseInwardDate).format('YYYY-MM-DD'));
          setinvoiceNo(data.data.message.inwardNo);
          setsubtotal(data.data.message.subTotal);
          setgstamount(data.data.message.gstAmount);
          setGrandTotal(data.data.message.grandTotal);
          setAddButtonShow(true)
          setsubmitButtonName('Update')
        }
       })
      }
    },[])

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = `Bearer ` + localStorage.getItem('token');
    const config = {
            headers: { 'Authorization' : token,      
                    'Access-Control-Allow-Origin' : '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
            }
    };

    useEffect(()=>{
      var date = new Date();
      var currentMonth=('0'+(date.getMonth())).slice(-2);
      var formatedDate = `${date.getFullYear()}-${currentMonth}-${date.getDate()-1}`;
      //setdefaultdate(formatedDate);
      setpurchaseInwardDate(formatedDate);
      
      //getinwardno();
     // getPlates();
      //getPlatesizes();
       // getOptions();
	}, []);

         const getOptions = async (e) =>{
         setpartyType(e.target.value);
         if(e.target.value === 'buyer')
          {
        const res = await axios.get(baseURL + "customers/" , config)
        const data = res.data.message;
        const options = data.map(d => ({
          "value" : d.id,
          "label" : d.custName
        }))
      const otherdetails = data.map(d => ({
            "id" : d.id,
            "gstnumber" : d.custGstIN,
            "address" : d.fullAddress
          }))
          setselectOptions(options);
          setotherdetails(otherdetails);
          }else if (e.target.value === 'supplier')
          {
        const res = await axios.get(baseURL + "suppliers/" , config)   
        const data = res.data.message;
        const options = data.map(d => ({
          "value" : d.id,
          "label" : d.suppName
        }))
      const otherdetails = data.map(d => ({
            "id" : d.id,
            "gstnumber" : d.suppGstIN,
            "address" : d.fullAddress
          }))
          setselectOptions(options);
          setotherdetails(otherdetails);
          }
      }

      const eventhandleChange = (e)=>{
        //setState({id:e.value, name:e.label})
        setpartyName(e.label);
        setpartyId(e.value);
        otherdetails.forEach((item, i) => {
            if(item.id === e.value)
            {
                setgstnumber(item.gstnumber);
                setaddresss(item.address);
            }
          });

          if(partyType == "buyer")
          {
          //  alert(partyType);
          const custId = { customerId : e.value}
           axios.post(baseURL + "customers/platessizesbycustomer/", custId, config)
           .then(
               data => {
               //alert("allpalatesizes buyer: "+JSON.stringify(data))
                setallPlatesizeList(data.data.message);
               
                if(data.data.message[0]){
                  setplateSize(data.data.message[0]['plateSize'])
                  setplateSizeId(data.data.message[0]['plateSizeId'])
                  setplateId('1')
                  setplateName('Thermal')
                }
               }
           ).catch( error => console.log(error))
              }else{
               
                const res = axios.get(baseURL + "platesizes/", config)
                .then(
                  data => {
                   // alert("allpalatesizes supplier : "+JSON.stringify(data.data.message))
                   setallPlatesizeList(data.data.message);
                  }
                  ).catch(error => console.log(error))
                // alert(JSON.stringify(res))
                // const array = res.message;
                // alert(JSON.stringify(array))
                // setallPlatesizeList(array);
              }
              setAddButtonShow(true)
 }

 
    const addTableRows = ()=>{
        setSrno(srno + 1);
       // alert("platesizes : "+JSON.stringify(allPlatesizeList));
    const rowsInput={
            srno:srno,
            plates:allPlatesList,
            sizes: allPlatesizeList,
            plateName : plateName,
            plateId : plateId,
            plateSizeName : plateSize,
            plateSizeId : plateSizeId,
            quantity:'',
            rate:'0',
            amount:'',
        } 
        setRowsData([...rowsData, rowsInput])
    }

    const deleteTableRows = async (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        await setRowsData(rows);
        await calculateall();}
 
   const handleChange = async (index, evnt)=>{
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    rowsInput[index]['amount'] = (rowsInput[index]['quantity'] * rowsInput[index]['rate']);
    await setRowsData(rowsInput);   
    await calculateall();
    }


    const eventhandleChangePlateName = (i,e) => {
      const {options, selectedIndex} = e.target;
      setplateId(e.target.value);   
      setplateName(options[selectedIndex].innerHTML);
      const rowInput = [...rowsData];
      rowInput[i]['plateId'] = plateId
      rowInput[i]['plateName'] = plateName
      setRowsData(rowInput)
    
    } 

  
    const eventhandleChangePlateSize =(i,e) => {
      const {options, selectedIndex} = e.target;
      setplateSizeId(e.target.value);
      setplateSize(options[selectedIndex].innerHTML);
      
      const rowInput = [...rowsData];
      rowInput[i]['plateSizeId'] = plateSizeId
      rowInput[i]['plateSizeName'] = plateSize
      setRowsData(rowInput)
     
    }


  const handledatechange = (e)=>{ 
    setpurchaseInwardDate(e.target.value);
  }


    const calculateall = async ()=> {
        var subtot = rowsData.reduce((subtot, currentValue) => subtot = subtot + currentValue.amount, 0);
        var plateqty = rowsData.reduce((plateqty, currentValue1) => plateqty = Number(plateqty) + Number(currentValue1.quantity), 0);
        await setsubtotal(subtot);
        await setplatequantity(plateqty);
        var gstcal = (subtot *  gstPercentage)/100;
        await  setgstamount(gstcal);    
        var grtot=gstcal + subtot;
        await setGrandTotal(grtot);
    }
    const calculategstamoun = async(e)=>{
      var gstpercent = e.target.value;
       setgstPercentage(gstpercent);
       var gstamt=(subtotal *  gstpercent)/100;
       await  setgstamount(gstamt);
       var grtot=gstamt + subtotal;
       await setGrandTotal(grtot);
    }
   const bodyParameters = {
      partyType : partyType,
      purchaseInwardDate:purchaseInwardDate,
      inwardNo:invoiceNo,
      partyName:partyName,
      partyId:partyId,
      partyAddress:addresss,
      partyGSTNo:gstnumber,
      totalPlateQuantity:platequantity,
      subTotal:subtotal,
      gstPercentage:gstPercentage,
      gstAmount:gstAmount,
      grandTotal:grandTotal,
      createdBy : createdBy,
      inwardData : rowsData
      };
      


//     const submitForm = async (event) =>{
//       console.log(localStorage.getItem('userId'));
//       event.preventDefault();
//      // alert(updateMethod);
//    if (updateMethod) 
//    {
//   // axios
//   // .put(baseURL +"createinward/"+ customerId,  
//   //  bodyParameters, config)
//   // .then(data =>{
//   //       console.log("data "+ data + " statuscode "+ data.data.statuscode);
//   //       if(data.data.statuscode === "200"){     
//   //       // window.location.href ='/customers';  
//   //       clearAllInputs();
//   //       getCustomers();
//   //       setcustomerId("");
//   //       setupdateMethod(false);
//   //       setsubmitButtonName("Create New Customer");
//   //     } 
//   //   })
//   // .catch(error => console.log(error));
// } else {    
//   console.log(bodyParameters.inwardData);
// axios
// .post(baseURL+"purchaseinwards/createinward",  
//  bodyParameters, config)
// .then(data =>{
//     // alert(JSON.stringify(data));
//      if(data.data.statuscode === "200"){  
//         alert("data saved");
//       // window.location.href ='/customers';  
//       //clearAllInputs();
//     //  getCustomers();
//     } 
//   })
// .catch(error => console.log(error));
// }} 


const submitForm = async (event) =>{
event.preventDefault();
axios
.post(baseURL+"purchaseinwards/createinward",  
bodyParameters, config)
.then(data =>{
// alert(JSON.stringify(data));
 if(data.data.statuscode === "200"){  
  //  alert("data saved");
  window.location.href ='/purchaseinward';  
  //clearAllInputs();
//  getCustomers();
} 
})
.catch(error => console.log(error));
}


    const cardElementOptions = {
        style: {
          base: {
            color: "#666",
            fontSize: "20px",
          },
          invalid: {
            color: "#fa755a",
            fontSize: "20px",
          }
        }
      }
      const styles = {
        fontSize: 8,
        color: 'blue',
        
      }

  return (
    <>
    
    <TopNavbar />
      <Container>
      <form autoComplete="off" onSubmit={submitForm}>
      <Row>
        <Col xs={12} lg={2}>
          Plates Inward From  :  
          </Col> 
          <Col xs={12} lg={6}>    
          <input type="radio"  name="partyType" value="supplier" onChange={(evnt)=>(getOptions(evnt))} checked={(partyType === "supplier") ? true : false} /> Supplier
            &nbsp;<input type="radio" name="partyType" value="buyer" onChange={(evnt)=>(getOptions(evnt))} checked={(partyType === "buyer") ? true : false} /> Buyer
           
           </Col>
        <Col xs={12} lg={4} className="text-end">
            <input  type="date" 
            value={purchaseInwardDate} className='form-control' name="purchaseInwardDate" onChange={(evnt)=>(handledatechange(evnt))} />
            </Col>
            
        </Row><br/>
        <Row>
            <Col xs={12} lg={10}>
            <Select className='selectinput' myFontSize="18px" style={styles.select} name="partyName" value={{"value" : 1,
          "label" : partyName}} options={selectOptions} onChange={(evnt)=>(eventhandleChange(evnt))}></Select>
            </Col>
            <Col xs={12} lg={2}>
              <input type="text" name="invoiceNo" placeholder='Enter Inward No.' value={invoiceNo} onChange={(e) => setinvoiceNo(e.target.value)} />
            </Col>
        </Row>
        <Row>
            <Col xs={12} lg={9} >
              <input type="hidden" name="partyAddress" value={addresss}/>
            Address : {addresss}
            </Col>
            <Col xs={12} lg={3} >
            <input type="hidden" name="partyGSTNo" value={gstnumber}/>
             GST No : {gstnumber}
            </Col>
        </Row>
      
      
<Row>
<table className="table">
    <thead>
        <tr>
            {/* <th xs={1} lg={1} >Sr.No</th> */}
            <th xs={2} lg={2}>Size</th>
            <th xs={2} lg={2}>Plate</th>
            <th xs={1} lg={1} className="text-end">Quantity</th>
            <th xs={1} lg={1} className="text-end">Rate</th>
            <th xs={2} lg={2} className="text-end">Amount</th>
            <th>
            <a className="btn btn-outline-success" onClick={addTableRows} style={{display : (addButtonShow)? "block" : "none"}} >+</a>
            </th>
        </tr>
    </thead>
    <tbody>
    <PurchaseItemAdd rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} ptype={partyType} eventhandleChangePlateName={eventhandleChangePlateName} eventhandleChangePlateSize={eventhandleChangePlateSize} />
    </tbody> 
    <tfoot>
        <tr>
            <td className="text-end" colSpan={2}> </td>
            <td className="text-end" > Total Plates</td>
            <td className="text-end">{platequantity} </td>
            <td className="text-end"> Sub Total</td>
            <td className="text-end">{subtotal}</td>
            <td></td>
            
        </tr>
        <tr>
            <td className="text-end" colSpan={5}>GST @ 
            <select value={gstPercentage} onChange={(evnt)=>(calculategstamoun(evnt))}>
                <option value={0}> 0% </option>
                <option value={5}> 5% </option>
                <option value={12}> 12% </option>
                <option value={18}> 18% </option>
                <option value={28}> 28% </option>
            </select></td>
            <td className="text-end">{gstAmount}</td>

        </tr>
        <tr>
            <td className="text-end" colSpan={6}>CGST @ {gstPercentage/2} %</td>
            <td className="text-end">{gstAmount/2}</td>
        </tr>
        <tr>
            <td className="text-end" colSpan={6}>SGST @ {gstPercentage/2} %</td>
            <td className="text-end">{gstAmount/2}</td>
        </tr>
        <tr>
            <td colSpan={5} className="text-end"> Grand Total </td>
            <td className="text-end">{grandTotal}</td>
        </tr>
    </tfoot>
</table>
<input type="hidden" name="totalPlateQuantity" value={platequantity}/>
<input type="hidden" name="subTotal" value={subtotal}/>
<input type="hidden" name="gstPercentage" value={gstPercentage}/>
<input type="hidden" name="gstAmount" value={gstAmount}/>
<input type="hidden" name="grandTotal" value={grandTotal}/>
</Row>
<Row>       <Col xs={12} lg={12}>
            <button type="submit" className="btn btn-success">{submitButtonName}</button>                
            </Col>
        </Row>
        </form>
{/* <Row>
<PurchaseItemAdd sr={srno}/>
</Row> */}

{/* <Row>
    <Col>
    <button onClick={addmoreelement} >Add More Rows</button></Col>
</Row> */}

      </Container>
    </>
  )

  

}


export default Purchaseinward
