import React, {useState, useEffect} from 'react'
import CustomerSelect from '../components/CustomerSelect'
import InputField from '../components/InputField'
import PlateNames from '../components/plateName'
import PlateSizes from '../components/plateSize'
import DeliveryChallanPrint from './DeliveryChallanPrint'
import { Container, Row, Col } from 'react-bootstrap'
import TopNavbar from '../navbar/navbar';
import axios from 'axios';
import "./deliverychallan.css";
import moment from 'moment';




function toJSONLocal(date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}



const DeliverChallan = () => {
  const [srno, setSrno] =useState(1);
  const [inputValue, setInputValue] = useState({ challanDate: toJSONLocal(new Date()), challanNo: "", quantity :"" , narration : "",jobCardno:""});
  const { challanDate, challanNo, quantity , narration, jobCardno } = inputValue;
  const [partyName, setpartyName] = useState();
  const [partyId, setpartyId] = useState();
  const [plateId, setplateId] = useState();
  const [plateName, setplateName] = useState();
  const [allPlateSizes, setallPlateSizes] = useState([]);
  const [plateSizeId, setplateSizeId] = useState();
  const [plateSize, setplateSize] = useState();
  const [partyAddress, setpartyAddress] = useState();
  const [partyGSTNo, setpartyGSTNo] = useState();
  const [rowsData, setRowsData] = useState([]);
  const [deliveryQuantity, setdeliveryQuantity] = useState("0");
  const [lessonImage, setLessonImage] = React.useState([]);
  var sr=0;
  useEffect(()=>{
    setInputValue({
      challanDate :  toJSONLocal(new Date())
    })
    inputValue.challanDate = toJSONLocal(new Date());
    getChallanNumber();
  }, []);
  
const baseURL = process.env.REACT_APP_BASE_URL;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy = localStorage.getItem('userId');
const config = {
        headers: { 'Authorization' : token,      
                'Access-Control-Allow-Origin' : '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers':'Origin, Content-Type, X-Auth-Token'        
         }
};

const getChallanNumber = () =>{ 

  var date = new Date();
  const currentMonth = ('0'+ (date.getMonth() +  1)).slice(-2);
    var formatedDate = `${date.getFullYear()}-${currentMonth}-${date.getDate()-1}`;
  axios.post(baseURL + "deliverchallans/getchallanno/", {currentMonth}, config)
  .then(
      data => {
         setInputValue({
          challanNo : data.data.message,
          challanDate :  (moment(new Date())).subtract(1, 'days').format('YYYY-MM-DD')
         })
        //  inputValue.challanNo = data.data.message;
      }
  ).catch( error => console.log(error))
}
  const eventhandleChange = async (e)=>{
    var index = e.target.selectedIndex;
    var optionElement = e.target.childNodes[index]
    var address =  optionElement.getAttribute('data-address');
    var gstIn =  optionElement.getAttribute('data-gstno');
    var custName = optionElement.getAttribute('data-custname');
    setpartyId(e.target.value);
    setpartyName(custName);
    setpartyAddress(address);
    setpartyGSTNo(gstIn);
    // console.log(partyAddress);
    const custId = { customerId : e.target.value}
   await axios.post(baseURL + "customers/platessizesbycustomer/", custId, config)
    .then(
        data => {
            setallPlateSizes(data.data.message);
        }
    ).catch( error => console.log(error))
       }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log(inputValue);
  };
const calculateTotalQuantity = async () => {
  var totdeliverquantity = 0;
  rowsData.forEach((rows)=>{
totdeliverquantity = parseFloat(totdeliverquantity) + parseFloat(rows.quantity);
  })
  await setdeliveryQuantity(totdeliverquantity);
}

  const handleLessonImage = (event) => {
    const rowsInput= rowsData;
    
    let totdeliverquantity = 0;
    for (let i = 0; i < event.target.files.length; i++) {
     var quantity = 0;
    //  console.log(event.target.files[i].name.split('.') + " Split . Only");
    //  console.log(event.target.files[i].name.split('.').reverse()[1] + "Split With Revers");
    //  var name1 = event.target.files[i].name.split('.').reverse()[1];
    //  var Newname = event.target.files[i].name.split('.').reverse()[1];
    var Newname = event.target.files[i].name.split(/\.(?=[^\.]+$)/);
     console.log(Newname);
    //  if(name1.indexOf('__') > -1){
    //   console.log(name1.split('__').reverse()[0])
    //   quantity  = name1.split('__').reverse()[0];
    //   Newname= name1.split('__').reverse()[1]
    // }
      sr=sr + 1;
      console.log(sr);
      rowsInput.push({
      // srno:sr,
      plateName : '',
      plateId : '',
      plateSizeName : '',
      plateSizeId : '',
      quantity: (4 * quantity ),
      narration: Newname[0], 
      jobCardno: '',  
      allPlateSizesList :''       
  });
  
   totdeliverquantity = parseFloat(totdeliverquantity) + parseFloat(deliveryQuantity) + parseFloat(4 * quantity);
  }
  setdeliveryQuantity(totdeliverquantity);
  setRowsData(rowsInput)   
  setSrno(sr);
  };
  const handleChangeTable = async (index, evnt)=>{
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    await setRowsData(rowsInput);
    calculateTotalQuantity();
    }

    const eventhandleChangePlateNameTable = async (index, event)=> {
      const { name, value ,options, selectedIndex} = event.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      rowsInput[index]['plateName'] = options[selectedIndex].innerHTML;
      rowsInput[index]['allPlateSizesList'] = allPlateSizes;    
    await setRowsData(rowsInput);
    // console.log(rowsData);
    }
    const eventhandleChangePlateSizeTable = async (index, event)=>  {
      const { name, value,options, selectedIndex } = event.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
      rowsInput[index]['plateSizeName'] = options[selectedIndex].innerHTML;
      setplateSizeId( value);
      setplateSize(event.target.innerHTML);
      await setRowsData(rowsInput);
    }
  const eventhandleChangePlateName = (e) => {
    const {options, selectedIndex} = e.target;
    setplateId(e.target.value);
    setplateName(options[selectedIndex].innerHTML);
  }

  const eventhandleChangePlateSize =(e) => {
    const {options, selectedIndex} = e.target;
    setplateSizeId(e.target.value);
    setplateSize(options[selectedIndex].innerHTML);
  }
  
  const addRowintoTable = (e) => {
    const rowsInput={
      // srno:srno,
      plateName : plateName,
      plateId : plateId,
      plateSizeName : plateSize,
      plateSizeId : plateSizeId,
      quantity: quantity,
      narration: narration, 
      jobCardno: jobCardno, 
      allPlateSizesList: allPlateSizes,
        
  } 
  setRowsData([...rowsData, rowsInput])    
  setSrno(srno + 1);
  var totdeliverquantity = parseFloat(deliveryQuantity) + parseFloat(quantity);
  setdeliveryQuantity(totdeliverquantity);
    
  }
  const bodyParameters = {
    challanDate: challanDate,
    challanNo: challanNo,
    status: 1,       
    partyId: partyId,
    partyName: partyName,
    partyAddress: partyAddress,
    partyGSTNo: partyGSTNo,
    deliveryQuantity: deliveryQuantity,        
    createdBy : createdBy,
    dcData : rowsData
  }
  const submitForm = async (event) =>{
    event.preventDefault();
    // console.log(bodyParameters);
    axios.post(baseURL+"deliverchallans/createdeliverychallan/",  
 bodyParameters, config)
 .then(data =>{ 
  if(data.data.statuscode === "200") {    
  // console.log("Challan Created Successfully");
  window.location.href ='/deliverychallan/'; 

 }

 })
 .catch(error => console.log(error))
  }

  return (   
   <>
   <TopNavbar />
      <Container>
        <Row>
       <Col xs={12} lg={12} sm={8}>
       <Row>
    <Col xs={12} lg={8} sm={8}>
       <CustomerSelect value={partyId} name="partyId" label="Customer Select" onChange={eventhandleChange} /></Col>
    <Col xs={12} lg={4} sm={4}> <InputField
        //  defaultValue={defaultdate}
         type="date"
         value={challanDate}
         placeholder="Challan Date"
         label="Challan Date"
         name="challanDate"
         onChange={handleChange}
       />
       </Col>

       </Row>
       <Row>
        <Col xs={12} lg={8} sm={8}>
      Address : {partyAddress}
<br/>
      GST Number : {partyGSTNo}
      </Col>
       <Col xs={12} lg={4} sm={4}>
       <InputField
         type="text"
         value={challanNo}
         placeholder="Challan Number"
         label="Challan Number"
         name="challanNo"
         onChange={handleChange}
       /></Col>
       </Row>
       <Row>
       <Col xs={12} lg={12} sm={12}>
       <input type="file" onChange={handleLessonImage} multiple
         placeholder="jobfiles"
         label="jobfiles"
         name="jobfiles[]"
        />
       {/* <InputField
         type="file"
         multiple
        // value={jobfiles}
         placeholder="jobfiles"
         label="jobfiles"
         name="jobfiles[]"
         onChange={handleLessonImage}
        /> */}
       </Col>
        {lessonImage.map((item, index) => {
        return (
          <div key={index}>
          {/* <h3>{item.name}</h3> */}
              
          </div>
        );
      })}
       </Row>
   <Row className='rowtoadd'>
   <Col xs={3} lg={3} sm={4}> <InputField
         type="text"
         value={narration}
         placeholder="Job Description"
         label="Job Description"
         name="narration"
         onChange={handleChange}
       /> </Col>
      
    <Col xs={2} lg={2} sm={4}> <PlateNames value={plateId} name="plateId" label="Plate Name" onChange={eventhandleChangePlateName}/></Col>
    <Col xs={2} lg={2} sm={4}> <PlateSizes value={plateSizeId} name="plateSizeId" label="Plate Size" onChange={eventhandleChangePlateSize}  allPlateSizes={allPlateSizes}/></Col>
    
    <Col xs={2} lg={2} sm={2}><InputField
         type="number"
         value={quantity}
         placeholder="Quantity"
         label="Quantity"
         name="quantity"
         onChange={handleChange}
       /> </Col>
        <Col xs={2} lg={2} sm={4}> <InputField
         type="text"
         value={jobCardno}
         placeholder="Job Card No"
         label="Job Card No"
         name="jobCardno"
         onChange={handleChange}
       /> </Col>
    <Col xs={1} lg={1} sm={1}> <a className="btn btn-success margin-top-25" onClick={addRowintoTable}> + </a> </Col>
   </Row>  
   <Row>    
   <Col xs={12} lg={12} sm={12}> 
    <table className='borderTable'> 
            <thead>
                <tr>
                    {/* <td>Sr. No</td> */}
                    <td>Job Description</td>
                    <td>Plate Name</td>
                    <td>Plate Size</td>
                    <td>Quantity</td>
                    <td>Job Card No</td>
                </tr>
            </thead>
            <tbody style={{lineHeight : "0px"}}>
            {rowsData.map((x, i) =><tr>
              {/* <td className='width10'>{x.srno}</td> */}
              <td className='width30'> {x.narration}</td> 
           <td className='width30'>
              <PlateNames value={x.plateId} name="plateId" label="" 
              onChange={(event)=> eventhandleChangePlateNameTable(i, event)}/>
                </td> 
                
                <td className='width10'>
                <PlateSizes value={x.plateSizeId} name="plateSizeId" label="" onChange={(event)=>  eventhandleChangePlateSizeTable(i, event)}  allPlateSizes={x.allPlateSizesList}/>
                  </td> 
                  <td className='width10'>
                  <InputField
         type="text"
         value={x.quantity}
         placeholder="Quantity"
         label=""
         name="quantity"
         onChange={(evnt)=>(handleChangeTable(i, evnt))}
       />
                    </td>
                  <td className='width30'>
            <InputField
         type="text"
         value={x.jobCardno}
         placeholder="Job Card Number"
         label=""
         name="jobCardno"
         onChange={(evnt)=>(handleChangeTable(i, evnt))}
       />
              </td>
        </tr> ) }
            </tbody>
            <tfoot>
                <tr>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> Total </td>
                    <td> {deliveryQuantity}</td>
                    <td> </td>
                </tr>
            </tfoot>
        </table>
    </Col>
    </Row> 
    <Row>
      <Col><a type="submit" className='btn btn-success' onClick={submitForm}> Submit </a></Col>
    </Row>
       </Col>   
       <Col xs={4} lg={4} sm={4}>
  <DeliveryChallanPrint partyName={partyName} gstNo={partyGSTNo} partyAddress={partyAddress} challanNo={challanNo} challanDate={challanDate} rowsData={rowsData} deliveryQuantity={deliveryQuantity} />
   </Col>
   </Row>
   </Container>
   </>
  )
}

export default DeliverChallan
