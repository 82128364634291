import React from "react";

function PlateSizes({ value, name, label, onChange, allPlateSizes}) {    
    
  return (
    <>
    <label htmlFor="input-field"> {label}</label>
    <select className="form-select" name={name} value={value} onChange={onChange}>
        <option value=""> Please Select Plate Size </option>
        { allPlateSizes ? allPlateSizes.map( (x, i) =>
            // <option key={x} value={x.id} >{x.plateSizeName}</option>
            <option key={x} value={x.plateSizeId} >{x.plateSize}</option>
        ) : ''} 

    </select>
    </>
  )
}

export default PlateSizes
